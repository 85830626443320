<template>
  <div class="detailed-segment">
    <div class="detailed-segment__checkpoint">
      <div class="detailed-segment__datetime">
        <p class="detailed-segment__time">{{ departureTime }}</p>
        <p class="detailed-segment__date">{{ departureDate }}</p>
      </div>
      <p class="detailed-segment__airport">{{ departureCheckpoint }}</p>
    </div>
    <div :class="{'detailed-segment__flight-details': true, 'detailed-segment__flight-details--shrinked': shrinked}">
      <img :src="segment.airline.image" class="detailed-segment__airline-logo" />
      <p class="detailed-segment__flight">{{ flight }}</p>
    </div>
    <div class="detailed-segment__checkpoint">
      <div class="detailed-segment__datetime">
        <p class="detailed-segment__time">{{ arrivalTime }}</p>
        <p class="detailed-segment__date">{{ arrivalDate }}</p>
      </div>
      <p class="detailed-segment__airport">{{ arrivalCheckpoint }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .detailed-segment {
    margin-left: 10px;
  }

  .detailed-segment__checkpoint {
    border-bottom: 1px solid rgba(51, 51, 51, 0.07);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .detailed-segment__datetime {
    display: flex;
    align-items: flex-end;
  }

  .detailed-segment__time {
    font-weight: bold;
    font-size: 14px;
    color: rgba(51, 51, 51, 0.9);
    width: 46px;
  }

  .detailed-segment__date {
    color: rgba(51, 51, 51, 0.9);
    font-size: 11px;
  }

   .detailed-segment__airport {
    font-size: 11px;
    color: rgba(51, 51, 51, 0.9);
  }

  .detailed-segment__flight-details {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--shrinked {
      margin: 0;
    }
  }

  .detailed-segment__airline-logo {
    width: 15px;
    height: 15px;
    margin-left: -20px;
  }

  .detailed-segment__flight {
    margin: 6px 0;
    color: rgba(51, 51, 51, 0.45);
    font-size: 11px;
  }
</style>

<script>
import { formatTime, formatFullDate } from '/app/date-utils'

const formatCheckpoint = ({ city, name, code }) => name || city || code

export default {
  props: {
    segment: {
      type: Object
    },
    shrinked: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    departureCheckpoint () {
      return formatCheckpoint(this.segment.departure.airport)
    },

    departureTime () {
      return formatTime(this.segment.departure.timestamp)
    },

    departureDate () {
      return formatFullDate(this.segment.departure.timestamp)
    },

    arrivalTime () {
      return formatTime(this.segment.arrival.timestamp)
    },

    arrivalDate () {
      return formatFullDate(this.segment.arrival.timestamp)
    },

    arrivalCheckpoint () {
      return formatCheckpoint(this.segment.arrival.airport)
    },

    flight () {
      const { segment } = this
      const { airline } = segment
      return `${airline.name}, ${airline.code} ${segment.number}`
    }
  }
}
</script>
