<template>
  <div>
    <div
      class="offer__login-popup"
      v-if="offer.hasWriteAccess && shouldShowLoginPopup"
    >
      <LoginPopup
        @cancel="onCancelLogin"
        :cancellable="false"
        :offer-id="offer.id"
      >
      </LoginPopup>
    </div>
    <avi-layout :show-footer="offer && offer.hasWriteAccess">
      <template v-slot:subscription-menu>
        <SubscriptionBanner v-if="offer.hasWriteAccess && project.subscriptionsEnabled" />
      </template>

      <template v-slot:top-menu>
        <OfferControls
          v-if="offer && offer.hasWriteAccess"
          :showTutorial="tutorialState === 'newOffer'"
          @tutorialClick="onFinishTutorialNewOfferStep"
        />
      </template>

      <template v-slot:header>
        <OfferHeader
          v-if="offer && offer.hasWriteAccess"
          ref="offerHeader"
          :saved="saved"
          :offer="offer"
          :showTutorial="tutorialState === 'link'"
          @tutorialClick="onFinishTutorialLinkStep"
        >
        </OfferHeader>
        <OfferReadHeader v-else-if="offer" ref="offerHeader" :name="offerName"></OfferReadHeader>
      </template>

      <avi-inner-layout>
        <div class="offer__inner-layout-container">
          <span v-if="offer.hasWriteAccess" class="offer__client-view" @click="onPreview">{{ $t('clientView') }}</span>
          <avi-input
            v-if="offer && offer.hasWriteAccess"
            :disabled="true"
            :value="offerName"
            class="offer__input"
            @change="onNameChange"
          >
          </avi-input>

          <div v-if="offer">
            <OfferOption
              :key="option.tmpId"
              v-for="(option, index) in offer.options"
              :index="index + 1"
              :offer-option="option"
              :writeAccess="offer.hasWriteAccess"
              :offer-id="offer.id"
              :showTutorial="tutorialState === 'price'"
              @priceBlur="onPriceBlur"
              @close="onDeleteOption(index)"
              @destinationChange="onDestinationChange"
              @arrivalChange="onArrivalChange"
              @addNewTag="onAddNewTag(index, $event)"
              @priceChange="onPriceChange(index, $event)"
              @luggageChange="onLuggageChange(index, $event)"
              @changeTag="onChangeTag(index, $event)"
              @removeTag="onRemoveTag(index, $event)"
              @blurTag="onBlurTag(index, $event)"
              @closeTag="onCloseTag(index, $event)"
              @finishTutorialPriceStep="onFinishTutorialPriceStep"
            >
            </OfferOption>
          </div>
        </div>
      </avi-inner-layout>

      <template v-slot:footer>
        <ParsingInput
          :show-copy="true"
          :label="label"
          @parse="onParse"
          @copy="onCopyLink"
        />
      </template>
    </avi-layout>
    <Preview v-if="showClientPreview" :offerId="offer && offer.id" @close="onPreviewClose" />
  </div>
</template>

<style lang="scss" scoped>
  .offer__inner-layout-container {
    position: relative;
    scrollbar-width: none;
  }

  .offer__client-view {
    position: absolute;
    top: -12px;
    right: -9px;
    opacity: .8;
    transition: .2s ease;
    font-size: 11px;
    font-weight: bold;
    color: #765bad;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    @media screen and (max-width: 640px) {
      display: none;
    }
  }

  .offer__login-popup {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background:rgba(51, 51, 51, 0.8);
    z-index: 100;

    .login-popup {
      margin: 100px auto 0 auto;

      @media screen and (max-width: 900px) {
        margin: 0;
      }
    }
  }

  .offer__input.avi-input {
    font-size: 24px;
    color: rgba(51, 51, 51, 0.8);
    padding: 0 0 2px;
    font-weight: bold;
    display: block;
    margin: 16px 0 14px;
    width: 100%;
    font-family: "Open Sans", sans-serif;

    &:hover {
      color: rgba(51, 51, 51, 0.9);
    }

    &:focus {
      color: #333333;
    }

    @media screen and (max-width: 640px) {
      margin-top: 0;
    }
  }

  .offer__charter {
    width: calc(100% + 20px);
    cursor: pointer;
    margin: 0 -10px -10px;
  }
</style>

<script>
import throttle from 'lodash/throttle'
import { uuid } from 'uuidv4'
import { mapGetters } from 'vuex'
import { getOffer, updateOffer } from '../../api'
import ParsingInput from '../shared/components/ParsingInput'
import OfferControls from './components/OfferControls'
import OfferHeader from './components/OfferHeader'
import OfferReadHeader from './components/OfferReadHeader'
import OfferOption from './components/OfferOption'
import Preview from './components/Preview'
import LoginPopup from './components/LoginPopup'
import SubscriptionBanner from '../shared/components/SubscriptionBanner'
import { serializeOffer, copyOfferLink } from './utils'
import { sendEvent } from '../../amplitude.js'
import CharterImg from './bj.png'
import ConfImg from './am.png'
import CharterImgEn from './business_jets_en.png'
import { PROJECTS } from '../../constants'

export default {
  components: {
    ParsingInput,
    OfferControls,
    OfferHeader,
    OfferOption,
    OfferReadHeader,
    Preview,
    LoginPopup,
    SubscriptionBanner
  },

  data () {
    return {
      offer: null,
      saved: false,
      showClientPreview: false,
      throttledSave: throttle(this.save.bind(this), 1000),
      saveCount: 0,
      CharterImg,
      ConfImg,
      CharterImgEn,
      tutorialState: 'done' // price | link | newOffer | done
    }
  },

  async mounted () {
    this.getOffer()
  },

  methods: {
    async getOffer () {
      this.offer = await getOffer(this.$route.params.offerId)
      if (this.offer.hasWriteAccess && !this.$store.getters.tutorialFinished) {
        this.tutorialState = 'price'
      }
    },

    onPriceBlur () {
      this.checkAndProceedTutorial('price', 'link')
    },

    onFinishTutorialPriceStep () {
      this.checkAndProceedTutorial('price', 'link')
    },

    checkAndProceedTutorial (currentTutorialState, nextTutorialState) {
      if (currentTutorialState === this.tutorialState) {
        this.tutorialState = nextTutorialState
      }
    },

    onCharterClick () {
      sendEvent('bj-click')
      const url = 'https://businessjets.aero?utm_source=aviato'
      window.open(url, '_blank')
    },

    onConfClick () {
      sendEvent('conf-click')
      const url = 'http://airmarathon.space?utm_source=aviato'
      window.open(url, '_blank')
    },

    onDestinationChange ({ segmentId, value }) {
      this.segmentsById[segmentId].departure.alias = value
      this.throttledSave()
    },

    onArrivalChange ({ segmentId, value }) {
      this.segmentsById[segmentId].arrival.alias = value
      this.throttledSave()
    },

    onPriceChange (optionIndex, price) {
      this.offer.options[optionIndex].price = price
      this.throttledSave()
    },

    onLuggageChange (optionIndex, luggage) {
      this.offer.options[optionIndex].luggage = luggage
      this.checkAndProceedTutorial('price', 'link')
      this.throttledSave()
    },

    onAddNewTag (optionIndex) {
      this.offer.options[optionIndex].tags.push({
        tmpId: uuid(),
        name: '',
        active: false
      })
      this.checkAndProceedTutorial('price', 'link')
      this.throttledSave()
    },

    onChangeTag (optionIndex, { tagId, value }) {
      const offerOption = this.offer.options[optionIndex]
      const { tags } = offerOption
      offerOption.tags = tags.map(tag =>
        tag.tmpId === tagId
          ? { ...tag, name: value }
          : tag
      )

      this.checkAndProceedTutorial('price', 'link')
      this.throttledSave()
    },

    removeTag (optionIndex, tagId) {
      const { tags } = this.offer.options[optionIndex]
      this.offer.options[optionIndex].tags = tags.filter(tag => tag.tmpId !== tagId)
      this.checkAndProceedTutorial('price', 'link')
      this.throttledSave()
    },

    onRemoveTag (optionIndex, tagId) {
      this.removeTag(optionIndex, tagId)
      this.throttledSave()
    },

    onBlurTag (optionIndex, tagId) {
      if (!this.tagsById[tagId].name) {
        this.removeTag(optionIndex, tagId)
      } else {
        this.tagsById[tagId].active = true
      }
      this.checkAndProceedTutorial('price', 'link')
      this.throttledSave()
    },

    onFinishTutorialLinkStep () {
      this.checkAndProceedTutorial('link', 'newOffer')
    },

    onFinishTutorialNewOfferStep () {
      this.checkAndProceedTutorial('newOffer', 'done')
      this.$store.commit('finishTutorial')
    },

    onCloseTag (optionIndex, tagId) {
      this.removeTag(optionIndex, tagId)
      this.throttledSave()
    },

    onNameChange (e) {
      this.offer.name = e.target.value
      this.throttledSave()
      sendEvent('Offer name edit')
    },

    onParse (offerOption) {
      if (this.offer) {
        this.offer.options.push(offerOption)
        sendEvent('Create leg', { number: this.offer.options.length })
        if (window.fbq) {
          window.fbq('trackCustom', 'Create Leg')
        }
        if (this.project.name === PROJECTS.FLIGHTS) {
          window.gtag('event', 'conversion', { send_to: 'AW-969611177/nqzQCPOflaEYEKmvrM4D' })
        }
      }
      this.throttledSave()
    },

    onDeleteOption (deletedIndex) {
      sendEvent('Leg delete')
      if (this.offer.options.length === 1) {
        sendEvent('Leg delete single')
        this.$router.push({ path: '/' })
      } else {
        this.offer.options = this.offer.options.filter((_, index) => index !== deletedIndex)
        this.throttledSave()
      }
    },

    async save () {
      await updateOffer(serializeOffer(this.offer))
      this.saved = true
      ++this.saveCount
      this.$refs.offerHeader.animateHeader()
    },

    onPreview () {
      sendEvent('Preview')
      this.showClientPreview = true
    },

    onPreviewClose () {
      this.showClientPreview = false
    },

    onCopyLink () {
      copyOfferLink(this.offer.id, this.project.name)
    }
  },

  computed: {
    segmentsById () {
      if (!this.offer) {
        return {}
      }

      const res = {}
      for (const option of this.offer.options) {
        for (const segment of [...option.toDestination, ...option.fromDestination]) {
          res[segment.tmpId] = segment
        }
      }
      return res
    },

    offerName () {
      const { toDestination } = this.offer.options[0]
      const { airport: lastAirport } = toDestination[toDestination.length - 1].arrival
      const lastAirportName = lastAirport.city || lastAirport.name || lastAirport.code
      return this.$t('optionsTo').replace('{airport}', lastAirportName)
    },

    tagsById () {
      if (!this.offer) {
        return {}
      }

      const res = {}
      for (const option of this.offer.options) {
        for (const tag of option.tags) {
          res[tag.tmpId] = tag
        }
      }
      return res
    },

    label () {
      if (!this.offer) {
        return ''
      }
      return this.$t('optionHelp', { number: this.offer.options.length + 1 })
    },

    ...mapGetters(['shouldShowLoginPopup', 'user', 'country', 'project'])
  }
}
</script>
