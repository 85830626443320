<template>
  <Tag :focused="focused" @click="onClick" :active="active">
    <span
      :class="{
        'price-tag__placeholder': true,
        'price-tag__placeholder--active': active,
      }"
    >
      {{ $t('price') }}:
    </span>
    <span
      contenteditable="true"
      ref="input"
      :class="{
        'price-tag__input': true,
        'price-tag__input--active': active,
      }"
      type="text"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
      @keyup="onKeyUp"
    >
      {{ value }}
    </span>
  </Tag>
</template>

<style lang="scss" scoped>
  .price-tag__input {
    border: none;
    outline: none;
    background: transparent;
    min-width: 1px;
    color: #333333;
    display: inline-block;

    &--active {
      color: white;
    }
  }

  .tag {
    cursor: text;
  }

  .price-tag__placeholder--active {
    color: rgba(255, 255, 255, 0.5);
  }
</style>

<script>
import Tag from '../Tag'
import { sendEvent } from '../../../../amplitude.js'

export default {
  components: { Tag },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    initialValue: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      focused: false,
      value: this.initialValue
    }
  },

  methods: {
    onClick () {
      this.$refs.input.focus()
    },

    onFocus () {
      this.focused = true
    },

    onBlur () {
      this.focused = false
      this.$emit('blur')
      sendEvent('Price add')
    },

    onInput (e) {
      this.$emit('change', e.target.innerText)
    },

    onKeyUp (e) {
      const ENTER = 13
      if (e.keyCode === ENTER) {
        const value = e.target.innerText.trim()
        e.target.innerText = value
        this.$emit('change', value)
        this.$refs.input.blur()
      }
    }
  }
}
</script>
