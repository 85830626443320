<template>
  <div class="login-popup">
    <form class="login-popup__container" @submit="onSubmit">
      <p class="login-popup__emojis">
        <avi-emoji>👋</avi-emoji>
      </p>
      <p class="login-popup__title">{{ $t('loginPopupTitle') }}</p>
      <p class="login-popup__message">{{ $t('loginPopupMessage') }}</p>
      <div class="login-popup__input-wrapper">
        <input
          ref="emailInput"
          class="login-popup__input"
          type="email"
          :placeholder="$t('loginPopupPlaceholder')"
          v-model="email"
          required
        />
        <button
          :class="{
            'login-popup__input-button': true,
          }"
          :disabled="cleanedEmail.length === 0 || emailSent"
        >
          <span v-if="emailSent">✓ {{ $t('loginPopupSent') }}</span>
          <span v-else>{{ $t('loginPopupCta') }}</span>
        </button>
      </div>
      <p v-if="emailSent" class="login-popup__hint login-popup__hint--sent">
        {{  $t('loginPopupSentHint') }}
      </p>

    </form>
    <button class="login-popup__cancel" v-if="cancellable" @click="onCancel">
      {{ $t('loginPopupCancel') }}
    </button>
  </div>
</template>

<style>
  .war-link {
    color: blue;
    text-decoration: underline;
  }
</style>

<style lang="scss" scoped>
  .login-popup__container {
    margin: 0;
  }

  .login-popup__emojis {
    font-size: 36px;
  }

  .login-popup__message {
    font-size: 12px;
    color: #333;
  }

  .login-popup__input-wrapper {
    position: relative;
  }

  .login-popup__input-button {
    min-width: 92px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 3px;
    background-color: #765bad;
    opacity: 0.8;
    color: white;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: bold;
    transition: 100ms opacity;
    top: 6px;
    right: 6px;

    &:disabled {
      opacity: 0.4;
    }

    &:hover:enabled {
      opacity: 1;
    }
  }

  .login-popup {
    position: relative;
    padding: 28px 20px;
    background: white;
    border-radius: 10px;
    max-width: 434px;

    @media screen and (max-width: 900px) {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      max-width: none;
      border-radius: 0;
    }
  }

  .login-popup__cancel {
    color: #333;
    font-size: 11px;
    opacity: 0.25;
    font-weight: bold;
    position: absolute;
    top: 8px;
    right: 11px;
    transition: 100ms opacity;

    &:hover {
      opacity: 0.45;
    }
  }

  .login-popup__title {
    font-size: 24px;
    color: #333;
    font-weight: bold;
  }

  .login-popup__hint {
    font-size: 12px;
    color: #333;

    &--sent {
      margin-top: 4px;
    }
  }

  .login-popup__input {
    margin-top: 11px;
    display: block;
    width: 100%;
    padding: 10px 95px 10px 10px;
    border-radius: 4px;
    border: 1px solid rgba(51, 51, 51, 0.3);

    &:focus {
      border: 1px solid #333;
    }
  }
</style>

<script>
import { requestLoginLink } from '../../../../api'
import { sendEvent } from '../../../../amplitude'

export default {
  props: {
    offerId: String,
    cancellable: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      email: '',
      emailSent: false
    }
  },

  mounted () {
    this.$refs.emailInput.focus()
    sendEvent('openLoginPopup')
  },

  methods: {
    async onSubmit (e) {
      e.preventDefault()
      await requestLoginLink(this.cleanedEmail, this.offerId)
      sendEvent('submitEmail')
      this.emailSent = true
      setTimeout(() => {
        this.emailSent = false
      }, 5000)
    },

    onCancel () {
      sendEvent('closeLoginPopup')
      this.$emit('cancel')
    }
  },

  computed: {
    currentLocale () {
      return this.$i18n
    },

    cleanedEmail () {
      return this.email.trim()
    }
  }
}
</script>
