<template>
  <div
    :class="{
      tag: true,
      'tag--focused': focused,
      'tag--active': active
    }"
    @click="onClick"
  >
    <slot></slot>
    <div v-if="closable && active" class="tag__close" @click="onClose">
      <img class="tag__close-img" src="./images/close.svg" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .tag {
    position: relative;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
    border: 1px solid rgba(51, 51, 51, 0.3);
    padding: 1px 9px;
    line-height: 20px;
    color: rgba(51, 51, 51, 0.75);
    font-size: 12px;
    transition: .2 ease;
    overflow: hidden;

    &:hover {
      color: rgba(51, 51, 51, 0.9);
      border: solid 1px rgba(51, 51, 51, 0.5);
    }

    &--focused {
      background: #efefef;
      border: 1px solid #efefef;

      &:hover {
        border: 1px solid #efefef;
      }
    }

    &--active {
      border: 1px solid rgba(118, 91, 173, 0.9);
      background: rgba(118, 91, 173, 0.9);
      border-radius: 100px;
      color: white;

      &:hover {
        background-color: #765bad;
        border-color: #765bad;
        color: white;
      }
    }
  }

  .tag:hover {
    & .tag__close {
      display: flex;
    }
  }

  .tag__close {
    cursor: pointer;
    position: absolute;
    display: none;
    justify-content: flex-end;
    align-items: center;
    right: 4px;
    top: 2px;
    margin: auto;
    width: 20px;
    height: 19px;
    background-image: linear-gradient(to right,rgba(132, 108, 183, 0.1), #765bad);
  }

  .tag__close-img {
    width: 8px;
    height: 8px;
  }
</style>

<script>
export default {
  props: {
    focused: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    closeOnAnyClick: {
      type: Boolean,
      default: false
    },
    closable: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onClick () {
      if (this.closable && this.active && this.closeOnAnyClick) {
        this.$emit('close')
      } else {
        this.$emit('click', ...arguments)
      }
    },

    onClose () {
      this.$emit('close')
    }
  }
}
</script>
