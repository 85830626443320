<template>
  <div class="tooltip">
    <div class="tooltip__triangle tooltip__triangle--top" :style="tooltipStyles" v-if="isTop"></div>
    <div class="tooltip__inner">
      <div class="tooltip__head">
        <div class="tooltip__icon">
          <slot name="icon"></slot>
        </div>
        <div class="tooltip__step-counter">
          <span class="tooltip__current-step">{{ stepNumber }}</span>
          <span class="tooltip__max-steps">/ {{ stepsMax }}</span>
        </div>
      </div>
      <div class="tooltip__title">{{ title }}</div>
      <div class="tooltip__content">{{ content }}</div>
      <button class="tootlip__cta" v-if="cta" @click="onClick">
        {{ cta }}
      </button>
    </div>
    <div class="tooltip__triangle tooltip__triangle--bottom" :style="tooltipStyles" v-if="!isTop"></div>
  </div>
</template>

<style lang="scss" scoped>
  .tooltip {
    width: 410px;
    position: relative;
  }

  .tooltip__icon {
    font-size: 1.3em;
  }

  .tooltip__inner {
    padding: 10px;
    background: #765bad;
    border-radius: 6px;
  }

  .tooltip__head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .tooltip__title {
    margin: 4px 0 6px;
    color: #fff;
    font-weight: 700;
    font-size: 15px;
  }

  .tooltip__content {
    font-size: 12px;
    color: #fff;
    line-height: 15px;
  }

  .tooltip__current-step {
    font-size: 15px;
    color: #fff;
    font-weight: bold;
  }

  .tooltip__max-steps {
    font-size: 10px;
    color: #fff;
    opacity: 0.5;
  }

  .tootlip__cta {
    display: block;
    border-radius: 3px;
    background-color: #e4deef;
    border: solid 1px #e4deef;
    font-size: 12px;
    text-align: center;
    color: #765bad;
    width: 100%;
    margin-top: 8px;
    outline: none;
    cursor: pointer;
    font-size: 11px;
    font-weight: bold;
    height: 30px;
  }

  .tooltip__triangle {
    position: absolute;
    width: 0;
    height: 0;

    &--bottom {
      border-left: 18px solid transparent;
      border-right: 18px solid transparent;
      border-top: 18px solid #765bad;
    }

    &--top {
      top: -18px;
      border-left: 18px solid transparent;
      border-right: 18px solid transparent;
      border-bottom: 18px solid #765bad;
    }
  }
</style>

<script>
export default {
  props: [
    'title',
    'content',
    'stepNumber',
    'stepsMax',
    'orientation',
    'cta',
    'offsetLeft'
  ],

  methods: {
    onClick () {
      this.$emit('click')
    }
  },

  computed: {
    isTop () {
      return this.orientation === 'top'
    },

    tooltipStyles () {
      return {
        left: this.offsetLeft || 0
      }
    }
  }
}
</script>
