<template>
  <div class="detailed-offer-option">
    <div v-if="isCircular" class="detailed-offer-option__circular">
      <div class="detailed-offer-option__panel detailed-offer-option__panel--left">
        <p class="detailed-offer-option__panel-name">
          {{ $t('flightThere') }}
        </p>
        <div
          v-for="(segment, index) in offerOption.toDestination"
          :key="segment.tmpId"
        >
          <DetailedSegment :segment="segment"></DetailedSegment>
          <div
            v-if="index < offerOption.toDestination.length - 1"
            class="detailed-offer-option__transfer"
          >
            <img class="detailed-offer-option__transfer-img" src="./images/vertical-transfer.svg" />
            <p class="detailed-offer-option__transfer-details">
              {{ getTransferTime(offerOption.toDestination[index].arrival, offerOption.toDestination[index+1].departure) }}
            </p>
          </div>
        </div>
      </div>
      <div class="detailed-offer-option__panel detailed-offer-option__panel--right">
        <p class="detailed-offer-option__panel-name">
          {{ $t('flightBack') }}
        </p>
        <div
          v-for="(segment, index) in offerOption.fromDestination"
          :key="segment.tmpId"
        >
          <DetailedSegment :segment="segment"></DetailedSegment>
          <div
            v-if="index < offerOption.fromDestination.length - 1"
            class="detailed-offer-option__transfer"
          >
            <img class="detailed-offer-option__transfer-img" src="./images/vertical-transfer.svg" />
            <p class="detailed-offer-option__transfer-details">
              {{ getTransferTime(offerOption.fromDestination[index].arrival, offerOption.fromDestination[index+1].departure) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="detailed-offer-option__singular" v-else>
      <div
        v-for="segment in offerOption.toDestination"
        :key="segment.tmpId"
        class="detailed-offer-option__singular-segment"
      >
        <p class="detailed-offer-option__panel-name">
          {{ getSegmentTitle(segment) }}
        </p>
        <DetailedSegment :segment="segment" :shrinked="true"></DetailedSegment>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .detailed-offer-option__singular {
    width: 100%;
  }

  .detailed-offer-segment {
    margin-left: 10px;
  }

  .detailed-offer-option__circular {
    width: 100%;
    display: flex;

    @media screen and (max-width: 640px) {
      flex-direction: column;
    }
  }

  .detailed-offer-option__panel {
    padding: 12px 6px;
    width: 50%;
    box-sizing: border-box;

    @media screen and (max-width: 640px) {
      width: 100%;
      padding-left: 12px;
    }
  }

  .detailed-offer-option__panel--left {
    padding-right: 15px;
  }

  .detailed-offer-option__panel--right {
    border-left: 1px solid rgba(51, 51, 51, 0.1);
    padding-left: 20px;

    @media screen and (max-width: 640px) {
      border-left: none;
    }
  }

  .detailed-offer-option__panel-name {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 10px;
  }

  .detailed-offer-option__transfer {
    display: flex;
    align-items: center;
  }

  .detailed-offer-option__transfer-img {
    margin-left: -8px;
    margin-right: 8px;
  }

  .detailed-offer-option__transfer-details {
    margin: 12px 0;
    color: rgba(51, 51, 51, 0.9);
    font-size: 11px;
  }

  .detailed-offer-option__singular-segment {
    padding: 12px 12px;
  }
</style>

<script>
import DetailedSegment from '../DetailedSegment'

const calculateTransferTime = (arrivalTimestamp, departureTimestamp) => {
  const departureDate = new Date(departureTimestamp)
  const arrivalDate = new Date(arrivalTimestamp)
  const deltaInMilliseconds = departureDate - arrivalDate
  const deltaInMinutes = Math.ceil(deltaInMilliseconds / 1000 / 60)
  const hours = Math.floor(deltaInMinutes / 60)
  const minutes = deltaInMinutes % 60
  return [hours, minutes]
}

const getCity = ({ name, city, code }) => city || name || code

export default {
  components: {
    DetailedSegment
  },

  props: {
    offerOption: {
      type: Object
    }
  },

  methods: {
    getTransferTime (arrival, departure) {
      const [hours, minutes] = calculateTransferTime(arrival.timestamp, departure.timestamp)
      if (minutes === 0) {
        return this.$t('transferHours', { hours })
      }
      return this.$t('transferHoursMinutes', { hours, minutes })
    },

    getSegmentTitle (segment) {
      const { departure, arrival } = segment
      return `${getCity(departure.airport)} — ${getCity(arrival.airport)}`
    }
  },

  computed: {
    isCircular () {
      return this.offerOption.fromDestination.length > 0
    }
  }
}
</script>
