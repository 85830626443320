<template>
  <div class="offer-option offer-option__wrapper">
    <span class="offer-option__index">{{ index }}</span>
    <i class="offer-option__close" @click="onClose"></i>
    <div class="offer-option__card">
      <div class="offer-option__card-inner">
        <DetailedOfferOption
          v-if="isDetailedView"
          :offer-option="offerOption"
        >
        </DetailedOfferOption>
        <div v-else>
          <div v-if="isToDestinationCompound">
            <CompoundSegment
              :key="offerOption.toDestination[0].tmpId"
              :writeAccess="writeAccess"
              :isToDestination="true"
              :firstSegment="offerOption.toDestination[0]"
              :secondSegment="offerOption.toDestination[1]"
              @destinationChange="onDestinationChange(offerOption.toDestination[0].tmpId, $event)"
              @arrivalChange="onArrivalChange(offerOption.toDestination[1].tmpId, $event)"
            >
            </CompoundSegment>
          </div>
          <div v-else>
            <Segment
              v-for="segment in offerOption.toDestination"
              :writeAccess="writeAccess"
              :key="segment.tmpId"
              :segment="segment"
              :isToDesination="true"
              @destinationChange="onDestinationChange(segment.tmpId, $event)"
              @arrivalChange="onArrivalChange(segment.tmpId, $event)"
            >
            </Segment>
          </div>

          <div v-if="isFromDestinationCompound">
            <CompoundSegment
              :key="offerOption.toDestination[0].tmpId"
              :writeAccess="writeAccess"
              :firstSegment="offerOption.fromDestination[0]"
              :secondSegment="offerOption.fromDestination[1]"
              @destinationChange="onDestinationChange(offerOption.fromDestination[0].tmpId, $event)"
              @arrivalChange="onArrivalChange(offerOption.fromDestination[1].tmpId, $event)"
            />
          </div>
          <div v-else>
            <Segment
              v-for="segment in offerOption.fromDestination"
              :key="segment.tmpId"
              :writeAccess="writeAccess"
              :segment="segment"
              @destinationChange="onDestinationChange(segment.tmpId, $event)"
              @arrivalChange="onArrivalChange(segment.tmpId, $event)"
            >
            </Segment>
          </div>
        </div>
    </div>
      <EditableFooter
        v-if="writeAccess"
        :offer-option="offerOption"
        :is-price-active="isPriceActive"
        :is-detailed-view="isDetailedView"
        @priceChange="onPriceChange"
        @priceBlur="onPriceBlur"
        @luggageChange="onLuggageChange"
        @addNewTag="onAddNewTag"
        @blurTag="onCustomTagBlur"
        @changeTag="onChangeTag"
        @closeTag="onCloseTag"
        @toggleDetailsView="onToggleDetailsView"
      >
      </EditableFooter>
      <ReadableFooter
        v-else
        :offer-option="offerOption"
        :is-detailed-view="isDetailedView"
        @toggleDetailsView="onToggleDetailsView"
      >
      </ReadableFooter>
      <div v-if="showTutorial" class="offer-option__toltip">
        <avi-tooltip
          stepNumber="1"
          :stepsMax="TUTORIAL_STEPS"
          :title="$t('tutorialPriceTitle')"
          :content="$t('tutorialPriceContent')"
          :offsetLeft="'20px'"
          orientation="top"
          :cta="$t('tutorialLinkCta')"
          @click="onTutorialClick"
        >
          <template v-slot:icon>
            <avi-emoji>⚡</avi-emoji>
          </template>
        </avi-tooltip>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .offer-option__toltip {
    position: absolute;
    z-index: 1;
    bottom: -98px;
  }

  .offer-option .details-button {
    margin-top: -5px;
  }

  .offer-option__wrapper {
    position: relative;
    margin-top: 10px;

    &:hover .offer-option__close {
      display: block;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .offer-option__card-inner {
    padding: 2px 14px;

    @media screen and (max-width: 640px) {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .offer-option__card {
    border-radius: 4px;
    background: white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
  }

  .offer-option__close {
    cursor: pointer;
    display: none;
    position: absolute;
    background-image: url(./close.svg);
    top: 4px;
    right: 4px;
    width: 10px;
    height: 10px;

    &:hover {
      background-image: url(./close-red.svg)
    }
  }

  .offer-option__index {
    font-size: 8px;
    position: absolute;
    top: 4px;
    left: 4px;
    font-weight: bold;
    color: #333;
  }
</style>

<script>
import Segment from '../Segment'
import CompoundSegment from '../CompoundSegment'
import DetailedOfferOption from '../DetailedOfferOption'
import EditableFooter from '../EditableFooter'
import ReadableFooter from '../ReadableFooter'
import { sendEvent } from '../../../../amplitude.js'
import { TUTORIAL_STEPS } from '../../../../constants'

const isCompoundTravel = (segments) => {
  return segments.length === 2
}

export default {
  components: {
    Segment,
    CompoundSegment,
    DetailedOfferOption,
    EditableFooter,
    ReadableFooter
  },

  props: {
    writeAccess: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 1
    },
    offerOption: {
      type: Object
    },
    offerId: {
      type: String
    },
    showTutorial: {
      type: Boolean
    }
  },

  data () {
    return {
      TUTORIAL_STEPS,
      isDetailedView: false,
      isPriceActive: !!this.offerOption.price,

      // { state: 'none' }
      // { state: 'error' }
      // { state: 'waitingSearchId' }
      // { state: 'waitingSearchResults', searchId: string, id: number }
      // { state: 'searchResults', searchId: string, searchResults: { id: String, usdPrice: number, userPrice?: number, userCurrency?: string }[] }
      otaPrices: {
        state: 'none'
      }
    }
  },

  methods: {
    onClose () {
      this.$emit('close')
    },

    onDestinationChange (segmentId, value) {
      this.$emit('destinationChange', { segmentId, value })
    },

    onArrivalChange (segmentId, value) {
      this.$emit('arrivalChange', { segmentId, value })
    },

    onToggleDetailsView () {
      this.isDetailedView = !this.isDetailedView
      this.isDetailedView ? sendEvent('Leg more') : sendEvent('Leg less')
    },

    onPriceChange (price) {
      this.$emit('priceChange', price)
    },

    onPriceBlur () {
      this.$emit('priceBlur')
      this.isPriceActive = !!this.offerOption.price
    },

    onLuggageChange (luggage) {
      this.$emit('luggageChange', luggage)
    },

    onAddNewTag () {
      this.$emit('addNewTag')
    },

    onCustomTagBlur (tagId) {
      this.$emit('blurTag', tagId)
    },

    onChangeTag ({ tagId, value }) {
      this.$emit('changeTag', { tagId, value })
    },

    onCloseTag (tagId) {
      this.$emit('closeTag', tagId)
      sendEvent('Tag delete')
    },

    onTutorialClick () {
      this.$emit('finishTutorialPriceStep')
    }
  },

  computed: {
    isToDestinationCompound () {
      return isCompoundTravel(this.offerOption.toDestination)
    },

    isFromDestinationCompound () {
      return isCompoundTravel(this.offerOption.fromDestination)
    },

    luggageSelected () {
      return this.offerOption.luggage !== null
    }
  }
}
</script>
