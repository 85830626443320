<template>
  <div class="offer-header-wrapper">
    <!-- preload -->
    <img style="display: none;" src="./images/copy-done.svg" />
    <div class="offer-header offer-header--desktop">
      <div class="offer-header__link">
        <div class="offer-header__link-img-wrapper">
          <img src="./images/safe.svg" alt="secure" clsss="offer-header__link-img" />
        </div>
        <div class="offer-header__link-text">
          <p :class="{'offer-header__link-help': true, 'offer-header__link-help--animate': animating}">
            <span v-if="!saved">{{ $t('linkCreated') }}</span>
            <span v-else>{{ $t('savedMsg') }}</span>
          </p>
          <p class="offer-header__link-link">{{ link }}</p>
        </div>
      </div>
      <div class="offer-header__controls">
        <p
          :class="{'offer-header__control': true, 'offer-header__control--copied': textCopied}"
          @click="onCopyText"
        >
          <img v-if="textCopied" class="offer-header__control-icon offer-header__control-icon--done" src="./images/copy-done.svg" />
          <img v-else class="offer-header__control-icon" src="./images/text.svg" />
          &nbsp;
          <span v-if="textCopied">{{ $t('copiedMsg') }}</span>
          <span v-else> {{ $t('copyText') }}</span>
        </p>
        <p
          :class="{'offer-header__control': true, 'offer-header__control--copied': linkCopied}"
          @click="onCopyLink"
        >
          <img v-if="linkCopied" class="offer-header__control-icon offer-header__control-icon--done" src="./images/copy-done.svg" />
          <img v-else class="offer-header__control-icon" src="../../../shared/images/copy.svg" />
          &nbsp;
          <span v-if="linkCopied">{{ $t('copiedMsg') }}</span>
          <span v-else> {{ $t('copyLink') }}</span>
        </p>
      </div>
      <div v-if="showTutorial" class="offer-header--tooltip">
        <avi-tooltip
          stepNumber="2"
          :stepsMax="TUTORIAL_STEPS"
          :title="$t('tutorialLinkTitle')"
          :content="$t('tutorialLinkContent')"
          :cta="$t('tutorialLinkCta')"
          :offsetLeft="'30px'"
          orientation="top"
          @click="onTooltipClick"
        >
          <template v-slot:icon>
            <avi-emoji>✈️</avi-emoji>
          </template>
        </avi-tooltip>
      </div>
    </div>
    <div class="offer-header--mobile">
      <p class="offer-header__new-offer-link">
        <NewOfferLink></NewOfferLink>
      </p>
      <p :class="{
        'offer-header__link-help': true,
        'offer-header__link-help--animate': animating,
        'offer-header__link-help--mobile': true,
      }">
        <span v-if="!saved">{{ $t('linkCreated') }}</span>
        <span v-else>{{ $t('savedMsg') }}</span>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .offer-header--mobile {
    display: none;

    @media screen and (max-width: 640px) {
      display: block;
    }
  }

  .offer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .offer-header--desktop {
    @media screen and (max-width: 640px) {
      display: none;
    }
  }

  .offer-header__link {
    display: flex;
    align-items: center;
  }

  .offer-header__link-img-wrapper {
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: solid 1px rgba(84, 186, 119, 0.4);
    margin-right: 8px;
  }

  .offer-header__link-help {
    font-size: 10px;
    color: #333333;
    margin-bottom: -1px;

    &--mobile {
      padding-left: 5px;
    }
  }

  .offer-header__new-offer-link {
    margin-left: 5px;
  }

  .offer-header__link-link {
    font-size: 12px;
    text-align: left;
    color: #333333;
  }

  .offer-header__controls {
    display: flex;
    margin-top: 1px;
  }

  .offer-header__control {
    display: flex;
    align-items: center;
    opacity: .8;
    transition: .2s ease;
    color: #765bad;
    cursor: pointer;
    font-size: 11px;
    font-weight: bold;

    &--copied {
      cursor: auto;
      opacity: 1;
      color: #54BA77;
    }

    &:first-child {
      margin-right: 18px;
    }

    &:hover, &:active {
      opacity: 1;
    }
  }

  .offer-header__control-icon {
    width: 18px;
    height: 18px;

    &--done {
      width: 12px;
      height: 12px;
    }
  }

  .offer-header__control-text--copied {

  }

  @keyframes greenstatus {
    0% { color:#54ba77; }
    100% { color: #333; }
  }

  .offer-header__link-help--animate {
    animation: greenstatus 2s;
  }

  .offer-header--tooltip {
    position: absolute;
    top: 60px;
    left: calc(50% + 125px);
  }
</style>

<script>
import { copy, copyOfferLink, getOfferLink } from '../../utils'
import { offerToText } from './utils'

import NewOfferLink from '../NewOfferLink'
import { sendEvent } from '../../../../amplitude.js'
import { TUTORIAL_STEPS } from '../../../../constants'
import { mapGetters } from 'vuex'

export default {
  components: {
    NewOfferLink
  },

  props: {
    showTutorial: {
      type: Boolean
    },
    offer: {
      type: Object,
      default () {
        return null
      }
    },
    saved: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      TUTORIAL_STEPS,
      animating: false,
      linkCopied: false,
      textCopied: false
    }
  },

  computed: {
    ...mapGetters(['project']),

    link () {
      return getOfferLink(this.offer.id, this.project.name)
    },

    shouldShowTooltip () {
      return false
    }
  },

  methods: {
    onCopyLink () {
      copyOfferLink(this.offer.id, this.project.name)
      copy('https://' + this.link)
      this.linkCopied = true
      setTimeout(() => { this.linkCopied = false }, 1000)
      sendEvent('Copy link')
    },

    onCopyText () {
      copy(offerToText(this.offer, this.$i18n.locale))
      this.textCopied = true
      setTimeout(() => { this.textCopied = false }, 1000)
      sendEvent('Copy text')
    },

    animateHeader () {
      if (this.animating) {
        return
      }
      this.animating = true
      setTimeout(() => {
        this.animating = false
      }, 1000)
    },

    onTooltipClick () {
      this.$emit('tutorialClick')
    }
  }
}
</script>
