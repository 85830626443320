import clipboardCopy from 'clipboard-copy'
import humanizeDuration from 'humanize-duration'
import { PROJECTS } from '../../constants'

export const getInitialAirportValue = ({ code, name, city }) => {
  return city || name || code
}

const serializeCheckpoint = (checkpoint) => {
  return {
    alias: checkpoint.alias,
    airport: checkpoint.airport.code,
    timestamp: checkpoint.timestamp
  }
}

const serializeSegment = (segment) => {
  return {
    airline: segment.airline.code,
    number: segment.number,
    departure: serializeCheckpoint(segment.departure),
    arrival: serializeCheckpoint(segment.arrival)
  }
}

export const serializeOffer = (offer) => {
  return {
    id: offer.id,
    name: offer.name,
    options: offer.options.map((option) => ({
      toDestination: option.toDestination.map(serializeSegment),
      fromDestination: option.fromDestination.map(serializeSegment),
      price: option.price,
      luggage: option.luggage,
      tags: option.tags.map(({ name }) => name)
    }))
  }
}

export const getOfferLink = (offerId, projectName) => {
  const host = projectName === PROJECTS.AVIATO ? 'aviato.me' : 'pnr.flights'
  return `${host}/${offerId}`
}

export const copy = (text) => {
  clipboardCopy(text)
  if (window.parent) {
    window.parent.postMessage({
      type: 'copy-to-clipboard',
      toCopy: text
    },
    '*')
  }
}

export const copyOfferLink = (offerId, projectName) => {
  copy(`https://${getOfferLink(offerId, projectName)}`)
}

export const minutesToDuration = (durationMinutes, language) => {
  const MILLISECONDS_IN_SECONDS = 1000
  const SECONDS_IN_MINUTE = 60

  return humanizeDuration(durationMinutes * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECONDS, {
    language: language,
    fallbacks: ['ru']
  })
}
