import Vuex from 'vuex'

const LS_TERMS_KEY = 'acceptedTerms'

const pad = (value, length) => {
  return (value.toString().length < length) ? pad('0' + value, length) : value
}

export const createStore = ({
  tutorialFinished,
  user,
  country,
  project
}) => {
  return new Vuex.Store({
    state: {
      tutorialFinished,
      user,
      country,
      project,
      isShowingPaymentPopup: false,
      isShowingLoginPopup: false,
      isShowingThankYouPopup: false,
      acceptedTerms: !window.localStorage || !!window.localStorage.getItem(LS_TERMS_KEY),
      isShowingSubscriptionSetting: false
    },

    mutations: {
      finishTutorial (state) {
        state.tutorialFinished = true
      },

      acceptTerms (state) {
        state.acceptedTerms = true
        localStorage.setItem(LS_TERMS_KEY, true)
      },

      setLoginPopupVisibility (state, { isVisible }) {
        state.isShowingLoginPopup = isVisible
      },

      setThankYouPopupVisibility (state, { isVisible }) {
        state.isShowingThankYouPopup = isVisible
      },

      changePaymentPopupState (state, { isVisible }) {
        state.isShowingPaymentPopup = isVisible
      },

      changeSubscriptionSettingsState (state, { isVisible }) {
        state.isShowingSubscriptionSetting = isVisible
      }
    },

    getters: {
      tutorialFinished ({ tutorialFinished }) {
        return tutorialFinished
      },

      project ({ project }) {
        return project
      },

      isSubscribed ({ user }) {
        return user && user.isSubscribed
      },

      acceptedTerms ({ acceptedTerms }) {
        return acceptedTerms
      },

      shouldShowLoginPopup ({ user, isShowingLoginPopup }) {
        return !user && isShowingLoginPopup
      },

      user ({ user }) {
        return user
      },

      country ({ country }) {
        return country
      },

      isShowingPaymentPopup ({ isShowingPaymentPopup }) {
        return isShowingPaymentPopup
      },

      isShowingThankYouPopup ({ isShowingThankYouPopup }) {
        return isShowingThankYouPopup
      },

      isShowingSubscriptionSettings ({ isShowingSubscriptionSetting }) {
        return isShowingSubscriptionSetting
      },

      isShowingPopup ({ isShowingSubscriptionSetting, isShowingPaymentPopup, isShowingThankYouPopup }) {
        return isShowingSubscriptionSetting || isShowingPaymentPopup || isShowingThankYouPopup
      },

      subscriptionValidUntil ({ user }) {
        if (!user || !user.subscriptionEndDate) {
          return null
        }
        const subscriptionEndDate = new Date(user.subscriptionEndDate)
        return `${pad(String(subscriptionEndDate.getDate()), 2)}.${pad(String(subscriptionEndDate.getMonth() + 1), 2)}.${subscriptionEndDate.getFullYear()}`
      }

    },

    actions: {
      showLoginPopup ({ commit }) {
        commit('setLoginPopupVisibility', { isVisible: true })
      },

      hideLoginPopup ({ commit }) {
        commit('setLoginPopupVisibility', { isVisible: false })
      },

      showPaymentPopup ({ commit }) {
        commit('changePaymentPopupState', { isVisible: true })
      },

      hidePaymentPopup ({ commit }) {
        commit('changePaymentPopupState', { isVisible: false })
      },

      showSubscriptionSettings ({ commit }) {
        commit('changeSubscriptionSettingsState', { isVisible: true })
      },

      hideSubscriptionSettings ({ commit }) {
        commit('changeSubscriptionSettingsState', { isVisible: false })
      },

      showThankYouPopup ({ commit }) {
        commit('setThankYouPopupVisibility', { isVisible: true })
      },

      hideThankYouPopup ({ commit }) {
        commit('setThankYouPopupVisibility', { isVisible: false })
      }

    }
  })
}
