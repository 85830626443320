<template>
  <avi-popup>
    <div class="thank-you-popup">
    <p class="thank-you-popup__emojis">
    <avi-emoji>💙💛</avi-emoji>
    </p>
    <p class="thank-you-popup__title">
    {{ $t('thankYouPopupTitle') }}
    </p>
    <p class="thank-you-popup__message">
    {{ message }}
    </p>
    <div class="thank-you-popup__button-container">
        <button class="thank-you-popup__button" @click="hideThankYouPopup">{{ $t('thankYouPopupOkay') }}</button>
    </div>
</div>
  </avi-popup>
</template>

<style lang="scss" scoped>
.thank-you-popup {
    max-width: 434px;
    padding: 8px 2px;
}
  .thank-you-popup__emojis {
    font-size: 36px;
    line-height: 33px;
  }

  .thank-you-popup__message {
    font-size: 12px;
    color: #333;
    line-height: 16px;
  }

  .thank-you-popup__title {
    font-size: 24px;
    line-height: 36px;
    color: #333;
    font-weight: bold;
  }

  .thank-you-popup__button {
    background: #765BAD;
    mix-blend-mode: normal;
    opacity: 0.8;
    border-radius: 3px;
    padding: 4px 8px;
    color: white;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    transition: opacity 0.15s;
    margin-top: 12px;

    &:hover {
        opacity: 1;
    }
  }

  .thank-you-popup__button-container {
    display: flex;
    justify-content: flex-end;
  }
</style>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['subscriptionValidUntil']),

    message () {
      return this.$t('thankYouPopupMessage').replace('{validUntil}', this.subscriptionValidUntil)
    }
  },
  methods: {
    ...mapActions(['hideThankYouPopup'])
  }
}
</script>
