<template>
    <div class="container">
        <p>Total tk users: {{ userCount}}</p>
        <input type="email" placeholder="email" v-model="email" />
        <br/>
        <br/>
        <input type="password" v-model="password" placeholder="password" />
        <br/>
        <p v-if="error">{{ error }}</p>
        <button class="button" :disabled="loading" @click="addUser">Add user</button>
    </div>
</template>

<style scoped lang="scss">
.container {
    padding: 20px;
}
.button {
    border: 1px solid black;
    margin-top: 10px;
    padding: 5px;
}
</style>

<script>
import { getTkUserCount, addtkUser } from '../../api'

export default {
  data () {
    return {
      email: '',
      userCount: null,
      password: '',
      loading: false,
      error: ''
    }
  },

  async mounted () {
    this.userCount = await getTkUserCount()
  },

  methods: {
    async addUser () {
      this.loading = true
      this.error = ''
      try {
        const { userCount, error } = await addtkUser({
          email: this.email,
          password: this.password
        })
        if (error) {
          this.error = error
        } else {
          this.userCount = userCount
          this.error = ''
        }
      } catch (e) {
        console.error(e)
        this.error = 'Some server error'
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
