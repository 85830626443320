<template>
  <div>
    <div class="app__login-popup" v-if="shouldShowLoginPopup">
        <LoginPopup
          @cancel="cancelLoginPopup"
          :cancellable="true"
          :offer-id="null"
        >
        </LoginPopup>
      </div>

  <avi-layout>
    <template v-slot:subscription-menu>

      <SubscriptionBanner v-if="project.subscriptionsEnabled"/>
    </template>

    <template v-slot:top-menu>
      <div class="app__top-menu">
        <LanguageSwitcher />
      </div>
    </template>

    <template v-slot:header>
      <div class="app__header">
        <img src="./logo.svg" v-if="project.name === PROJECTS.AVIATO" />
        <img src="./logo-pnr-flights.svg" v-else />
      </div>
    </template>

    <template>
      <p class="app__icon-headline">
        <avi-emoji>💥</avi-emoji>
      </p>
      <p class="app__headline">
        {{ $t('mainHeadline') }}
      </p>
      <p class="app__subheadline">
        {{ $t('mainSubHeadline') }}
      </p>
      <div class="app__example">
        <div class="app__example-block app__example-block--mono">
          <p><pre>1  TS 275 J 15OCT 4 LGWYVR HK1         1010 1200   332 E 0</pre></p>
          <p><pre>2  AC8097 Y 15OCT 4 YVRSEA HK1         1335 1435   DH4 E 0</pre></p>
          <p><pre>3  AS2254 F 17OCT 6 SEAPDX HK1         1700 1753   E75 E 0</pre></p>
          <p><pre>4  AA7007 J 17OCT 6 PDXLHR HK1         1845 1210+1 788 E 0</pre></p>
        </div>
        <div class="app__example-divider">
          <img src="./arrow_a.svg" class="app__example-arrow" />
        </div>
        <div class="app__example-block" v-if="$i18n.locale === 'ru'">
          <p>15 окт., 10:10 – 12:00, Лондон (Гатвик) — Ванкувер, TS 275, Air Transat</p>
          <p>15 окт., 13:35 – 14:35, Ванкувер — Сиэтл (Такома), AC 8097, Air Canada</p>
          <p>17 окт., 17:00 – 17:53, Сиэтл (Такома) — Портленд, AS 2254, Alaska Airlines</p>
          <p>17 окт., 18:45 – 12:10, Портленд — Лондон (Хитроу), AA 7007, American Airlines</p>
        </div>
        <div class="app__example-block" v-else>
          <p>15 oct., 10:10 – 12:00, London (Gatwick) — Vancouver, TS 275, Air Transat</p>
          <p>15 oct., 13:35 – 14:35, Vancouver — Seattle (Tacoma), AC 8097, Air Canada</p>
          <p>17 oct., 17:00 – 17:53, Seattle (Tacoma) — Portland, AS 2254, Alaska Airlines</p>
          <p>17 oct., 18:45 – 12:10, Portland — London (Heathrow), AA 7007, American Airlines</p>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="app__parsing-input-wrapper">
        <ParsingInput
          :label="$t('optionHelp', { number: 1, numberSymbol: 'no.' })"
          @parse="onParse"
        />
      </div>
    </template>
  </avi-layout>
  </div>
</template>

<style lang="scss" scoped>
  .app__login-popup {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background:rgba(51, 51, 51, 0.8);
    z-index: 100;

    .login-popup {
      margin: 100px auto 0 auto;

      @media screen and (max-width: 900px) {
        margin: 0;
      }
    }

  }
  .app__icon-headline {
    font-size: 32px;
    margin-top: 20px;
  }

  .app__headline {
    font-size: 25px;
    font-weight: bold;
    color: #333;
    opacity: 0.9;
    margin: 2px 0 7px;
  }

  .app__subheadline {
    font-size: 14px;
    color: #333;
    opacity: 0.9;
    line-height: 1.86;
  }

  .app__header {
    padding: 9px 10px 0;
  }

  .app__parsing-input-wrapper {
    position: relative;
  }

  .app__tooltip-wrapper {
    position: absolute;
    bottom: 60px;
  }

  .app__example {
    margin-top: 16px;
    border-radius: 4px;
    border: solid 1px rgba(51, 51, 51, 0.1);
    color: #333;
  }

  .app__example-divider {
    border-bottom: solid 1px rgba(51, 51, 51, 0.1);
    margin: 0 10px;
    position: relative;
  }

  .app__example-block {
    margin: 10px;
    opacity: 0.9;
    font-size: 13px;
    opacity: 0.76;
    line-height: 1.38;

    &--mono {
      font-family: 'Roboto Mono', monospace;
    }

    & pre {
      padding: 0;
      margin: 0;
    }
  }

  .app__example-arrow {
    position: absolute;
    top: -20px;
    left: -20px;
  }

  .app__top-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
  }
</style>

<script>
import { mapGetters, mapActions } from 'vuex'

import { PROJECTS, TUTORIAL_STEPS } from '../../constants'
import ParsingInput from '../shared/components/ParsingInput'
import { createOffer } from '../../api'
import { sendEvent } from '../../amplitude.js'
import LanguageSwitcher from '../shared/components/LanguageSwitcher'
import SubscriptionBanner from '../shared/components/SubscriptionBanner'
import LoginPopup from '../Offer/components/LoginPopup'

export default {
  components: { ParsingInput, LanguageSwitcher, SubscriptionBanner, LoginPopup },

  data () {
    return {
      TUTORIAL_STEPS,
      PROJECTS
    }
  },

  methods: {
    async onParse (offerOptions) {
      const offer = await createOffer(offerOptions)
      sendEvent('Create link', { link: `https://aviato.me/${offer.id}` })
      sendEvent('Create leg', { number: 1 })
      if (window.fbq) {
        window.fbq('trackCustom', 'Create Leg')
      }
      if (this.project.name === PROJECTS.FLIGHTS) {
        window.gtag('event', 'conversion', { send_to: 'AW-969611177/nqzQCPOflaEYEKmvrM4D' })
      }
      this.$router.push(`/${offer.id}`)
    },

    cancelLoginPopup () {
      this.hideLoginPopup()
    },

    ...mapActions(['hideLoginPopup'])
  },

  computed: {
    shouldShowTooltip () {
      return !this.tutorialFinished
    },

    ...mapGetters(['tutorialFinished', 'shouldShowLoginPopup', 'project'])
  }
}
</script>
