<template>
  <div class="language-switcher__languages">
    <button class="language-switcher__language" @click="changeLanguage('en')" :disabled="$i18n.locale === 'en'">EN<button>
    <button class="language-switcher__language" @click="changeLanguage('es')" :disabled="$i18n.locale === 'es'">ES<button>
    <button class="language-switcher__language" v-if="project.name === PROJECTS.AVIATO" @click="changeLanguage('uk')" :disabled="$i18n.locale === 'uk'">UK (🇺🇦)<button>
    <button class="language-switcher__language" v-if="project.name === PROJECTS.AVIATO" @click="changeLanguage('ru')" :disabled="$i18n.locale === 'ru'">RU<button>
    <button class="language-switcher__language" @click="changeLanguage('fr')" :disabled="$i18n.locale === 'fr'">FR<button>
    <button class="language-switcher__language" v-if="project.name === PROJECTS.AVIATO" @click="changeLanguage('kz')" :disabled="$i18n.locale === 'kz'">KZ<button>
  </div>
</template>

<style lang="scss" scoped>
  .language-switcher__language {
    font-size: 12px;
    color: #765cad;
    opacity: 0.85;
    font-weight: bold;
    opacity: all 100ms;
    margin-right: 13px;

    &:hover:not(:disabled) {
      opacity: 1;
    }

    &:disabled {
      opacity: 0.5;
    }

    &:last-child {
      margin-right: 0;
    }
  }
</style>

<script>
import { setLocale } from '../../../api'
import { mapGetters } from 'vuex'
import { PROJECTS } from '../../../constants'

export default {
  data () {
    return {
      PROJECTS
    }
  },

  methods: {
    async changeLanguage (language) {
      await setLocale(language)
      window.location.reload()
    }
  },

  computed: {
    ...mapGetters(['project'])
  }
}
</script>
