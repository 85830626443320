import Vue from 'vue'
import queryString from 'query-string'
import { uuid } from 'uuidv4'
import { get } from 'lodash'

const offersCache = {}

export async function getLocale (languageGetParam) {
  const { forceLang } = queryString.parse(window.location.search)
  if (forceLang) {
    return forceLang
  }
  const response = await Vue.http.get('locale', {
    params: {
      lang: languageGetParam || undefined
    }
  })
  return response.body.locale
}

export async function getTutorialStatus () {
  const response = await Vue.http.get('tutorial')
  return response.body.tutorialFinished
}

export async function getProject () {
  const response = await Vue.http.get('project')
  return response.body
}

function findGetParameter (parameterName) {
  let result = null
  let tmp = []
  const items = location.search.substr(1).split('&')
  for (let index = 0; index < items.length; index++) {
    tmp = items[index].split('=')
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1])
  }
  return result
}

export async function getStartupData () {
  const user = await getUser(findGetParameter('tk_email'))
  const locale = await getLocale(findGetParameter('lang'))
  return Promise.all([
    getTutorialStatus(),
    getCountry(),
    getProject()
  ])
    .then(([tutorialFinished, country, project]) => {
      // location.search = ''
      return {
        locale,
        tutorialFinished,
        user,
        country,
        project
      }
    })
}

export async function setLocale (locale) {
  await Vue.http.post('locale', {
    language: locale
  })
}

function assignTmpIds (option) {
  option.tmpId = uuid()
  const tags = [...option.tags]
  option.tags = []
  for (const tag of tags) {
    option.tags.push({
      tmpId: uuid(),
      active: true,
      name: tag
    })
  }
  const segments = [...option.toDestination, ...option.fromDestination]
  for (const segment of segments) {
    segment.tmpId = uuid()
  }
}

export const parseInput = async (pnr) => {
  try {
    const response = await Vue.http.post('parse', { pnr })
    const { offerOption } = response.body
    assignTmpIds(offerOption)
    return {
      ok: true,
      offerOption
    }
  } catch (err) {
    const errorCode = get(err, 'body.errorCode')
    if (errorCode === 'TOO_MANY_PARSES') {
      return {
        ok: false,
        reason: 'TOO_MANY_PARSES'
      }
    } else {
      throw err
    }
  }
}

export const createOffer = async (offerOption) => {
  const adaptCheckpoint = ({ airport, ...other }) => ({
    airport: airport.code,
    ...other
  })

  const adaptSegment = ({ tmpId, airline, departure, arrival, ...other }) => ({
    airline: airline.code,
    departure: adaptCheckpoint(departure),
    arrival: adaptCheckpoint(arrival),
    ...other
  })

  const response = await Vue.http.post('offer', {
    offerOption: {
      toDestination: offerOption.toDestination.map(adaptSegment),
      fromDestination: offerOption.fromDestination.map(adaptSegment),
      parsedByPnr: offerOption.parsedByPnr
    }
  })

  const { offer } = response.body
  offersCache[offer.id] = offer
  return offer
}

export const getOffer = async (id) => {
  if (!offersCache[id]) {
    const response = await Vue.http.get('offer', {
      params: {
        id,
        disablewrite: !!queryString.parse(window.location.search).disablewrite,
        forceLang: queryString.parse(window.location.search).forceLang
      }
    })
    offersCache[id] = response.body.offer
  }

  const offer = offersCache[id]
  for (const option of offer.options) {
    assignTmpIds(option)
  }

  return offer
}

export const updateOffer = async (offer) => {
  const response = await Vue.http.put('offer', { offer })
  const { offer: updatedOffer } = response.body
  offersCache[updatedOffer.id] = updatedOffer
  return updatedOffer
}

export const requestLoginLink = async (email, offerId) => {
  const response = await Vue.http.post('auth/request_login_link', {
    email,
    offerId
  })
  return response.body.ok
}

export const login = async (loginId) => {
  const response = await Vue.http.post(`login/${loginId}`)
  return response.body
}

export const getUser = async (tkEmail) => {
  const response = await Vue.http.get('user', {
    params: {
      tk_email: tkEmail
    }
  })
  return response.body.user
}

export const getCountry = async () => {
  const response = await Vue.http.get('country')
  return response.body.country
}

export const createSubscription = async ({
  subscriptionType
}) => {
  const response = await Vue.http.post('subscribe', {
    subscriptionType
  })
  return response.body.pageUrl
}

export const getSubscriptions = async () => {
  const response = await Vue.http.get('subscriptions')
  return response.body.subscriptions
}

export const addSubscription = async ({
  userId,
  subscriptionDays
}) => {
  await Vue.http.post('subscriptions', {
    userId,
    subscriptionDays
  })
}

export const getTkUserCount = async () => {
  const response = await Vue.http.get('tk/user_count')
  return response.body.userCount
}

export const addtkUser = async ({
  email,
  password
}) => {
  const response = await Vue.http.post('tk/add_user', {
    email,
    password
  })
  return response.body
}

export const logout = async () => {
  await Vue.http.post('logout')
}
