<template>
  <SubscriptionLayout>
    <template v-slot:popup-title>
      <span>{{ $t('paymentPopupTitle') }}</span>
    </template>
      <template>
      <form class="subscription-selection__options">
          <input
            class="subscription-selection__radio"
            type="radio"
            name="payment-option"
            :selected="selected === 'MONTHLY'"
            @change="changeSubscription"
            id="payment-option-MONTHLY"
          />
          <label class="payment-option__label" for="payment-option-MONTHLY">
            <SubscriptionPlan type="MONTHLY" :highlighted="selected === 'MONTHLY'">
              <template v-slot:action>
                <SelectedCircle v-if="selected === 'MONTHLY'"></SelectedCircle>
                <UnselectedCircle v-else></UnselectedCircle>
              </template>
            </SubscriptionPlan>
          </label>

          <input
            class="subscription-selection__radio"
            type="radio"
            name="payment-option"
            :selected="selected === 'YEARLY'"
            @change="changeSubscription"
            id="payment-option-yearly"
          />
          <label class="payment-option__label" for="payment-option-yearly">
            <SubscriptionPlan type="YEARLY" :highlighted="selected === 'YEARLY'">
              <template v-slot:action>
                <SelectedCircle v-if="selected === 'YEARLY'"></SelectedCircle>
                <UnselectedCircle v-else></UnselectedCircle>
              </template>
            </SubscriptionPlan>
          </label>
      </form>
      <button class="subscription-selection__button" @click="pay">
        <span>{{ $t('paymentPopupCta')  }}</span>
      </button>
    </template>
  </SubscriptionLayout>
</template>

<style lang="scss" scoped>
    .subscription-selection__price-month {
      font-size: 12px;
    }

    .subscription-selection__options {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
    }

    .subscription-selection__radio {
      display: none;
    }

    .subscription-selection__button {
      background: rgba(118, 91, 173, 1);
      opacity: 0.8;
      color: white;
      width: 100%;
      padding: 7px 0;
      font-size: 12px;
      font-weight: bold;
      border-radius: 3px;

      &:hover:not(:disabled) {
        opacity: 1;
      }

      :disabled {
        cursor: default;
      }
    }

    .payment-option__label {
      display: block;
      width: 100%;

      &:last-child {
        margin-left: 12px;
      }
    }
  </style>

<script>
import SubscriptionLayout from '../shared/SubscriptionLayout.vue'
import SubscriptionPlan from '../shared/SubscriptionPlan.vue'
import SelectedCircle from './SelectedCircle'
import UnselectedCircle from './UnselectedCircle'
import { createSubscription } from '../../../api'

const options = ['MONTHLY', 'YEARLY']

export default {
  components: {
    SelectedCircle,
    UnselectedCircle,
    SubscriptionLayout,
    SubscriptionPlan
  },

  data () {
    return {
      selected: 'MONTHLY'
    }
  },

  methods: {
    changeSubscription () {
      this.selected = options.filter(option => option !== this.selected)[0]
    },

    async pay () {
      try {
        const pageUrl = await createSubscription({
          subscriptionType: this.selected
        })
        window.location.href = pageUrl
      } catch (e) {
        alert(this.$t('paymentErrorHappened'))
      }
    }
  }
}
</script>
