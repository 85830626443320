<template>
  <button
      :class="{
        'details-button': true,
        'details-button--compressing': !expandableArrows,
      }"
      @click="onClick"
    >
      <span>{{ title }}</span>
    </button>
</template>

<style scoped lang="scss">
  @mixin detailsIcons {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    background-repeat: no-repeat;
    background-image: url(./copy.svg);
    position: absolute;
    transition:
      top 0.15s ease,
      bottom 0.15s ease;
  }

  .details-button {
    padding-right: 14px;
    color: #765bad;
    font-size: 11px;
    font-weight: bold;
    opacity: 0.8;
    outline: none;
    position: relative;

    &:hover {
      opacity: 1;

      &::before {
        bottom: -7px;
      }

      &::after {
        top: -6px;
      }
    }

    &::before {
      @include detailsIcons;
      bottom: -5px;
      right: 0px;
    }

    &::after {
      @include detailsIcons;
      transform: rotate(180deg);
      top: -4px;
      right: 2px;
    }

    &--compressing {
      &:hover {
        &::before {
          top: 3px;
        }

        &::after {
          top: 1px;
        }
      }

      &::before {
        transform: rotate(180deg);
        right: 2px;
        top: 5px;
        bottom: 0;
      }

      &::after {
        transform: none;
        right: 0;
        top: -1px;
        bottom: 0;
      }
    }
  }
</style>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    expandableArrows: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
</script>
