<template>
  <div @click="onClick" class="parsing-input__wrapper">
    <div class="parsing-input__input-wrapper">
      <span class="parsing-input__label">{{ label }}</span>
      <input
        ref="input"
        class="parsing-input__input"
        :placeholder="$t('inputHelp')"
        v-model="value"
        @input="onInput"
        type="text"
      />
    </div>
    <div v-if="showCopy" @click="onCopy" class="parsing-input__copy-wrapper">
      <img class="parsing-input__copy-img" src="../../shared/images/copy.svg" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .parsing-input__input-wrapper {
    flex: 1 1;
  }

  .parsing-input__wrapper {
    border-radius: 4px;
    cursor: text;
    background: white;
    padding: 8px 14px;
    display: flex;

    &:hover {
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    }

    &:focus-within {
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.12);
    }

    &:hover {
      .parsing-input__input::placeholder {
        color: rgba(51, 51, 51, 0.8);
      }
    }
  }

  .parsing-input__input {
    display: block;
    width: 100%;
    border: none;
    outline: none;
    font-size: 12px;
    padding: 0;

    &::placeholder {
      color: rgba(51, 51, 51, 0.6);
    }

    @media screen and (max-width: 640px) {
      font-size: 16px;
    }
  }

  .parsing-input__label {
    font-size: 10px;
    color: #333333;
    font-weight: bold;
    opacity: 0.8;

    &::placeholder {
      color: rgba(51, 51, 51, 0.6);
    }
  }

  .parsing-input__copy-wrapper {
    cursor: pointer;
    border-left: 1px solid #eee;
    display: none;
    padding-left: 11px;

    @media screen and (max-width: 640px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover .parsing-input__copy-img {
      opacity: 1;
    }
  }

  .parsing-input__copy-img {
    opacity: 0.8;
    transition: opacity 0.2s;
  }
</style>

<script>
import throttle from 'lodash/throttle'

import { mapActions, mapGetters } from 'vuex'
import { parseInput } from '../../../api'

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    showCopy: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      value: '',
      lastCallId: 0,
      throttledTryParse: throttle(this.tryParse.bind(this), 1000)
    }
  },

  computed: {
    ...mapGetters(['user'])
  },

  mounted () {
    this.$refs.input.focus()
  },

  methods: {
    ...mapActions(['showPaymentPopup', 'showLoginPopup']),

    onInput (e) {
      const val = e.target.value.trim()
      if (val) {
        this.throttledTryParse(val)
      }
    },

    async tryParse (value) {
      const callId = ++this.lastCallId
      const parserResults = await parseInput(value)
      if (parserResults.ok) {
        const { offerOption } = parserResults
        if (callId === this.lastCallId) {
          this.$emit('parse', offerOption)
          this.value = ''
        }
      } else if (!this.user) {
        this.showLoginPopup()
      } else {
        this.showPaymentPopup()
      }
    },

    onClick () {
      this.$refs.input.focus()
    },

    onCopy () {
      this.$emit('copy')
    }
  }
}
</script>
