<template>
  <Tag
    :focused="focused"
    :active="active"
    :closable="active"
    @click="onClick"
    @close="onClose"
  >
    <div class="editable-tag__wrapper" :style="{minWidth: active ? 'auto' : minWidth}">
      <span
        contenteditable="true"
        ref="input"
        :class="{
          'editable-tag__input': true,
          'editable-tag__input--active': active,
        }"
        type="text"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
        @keyup="onKeyUp"
      >
        {{ value }}
      </span>
    </div>
  </Tag>
</template>

<style lang="scss" scoped>
  .editable-tag__input {
    border: none;
    outline: none;
    background: transparent;
    min-width: 1px;
    display: inline-block;
    color: #333333;
    height: 20px;
  }

  .editable-tag__input--active {
    color: white;
  }

  .editable-tag__wrapper {
    height: 20px;
  }

  .tag {
    cursor: text;
  }
</style>

<script>
import Tag from '../Tag'

export default {
  components: { Tag },

  props: {
    initialValue: {
      type: String,
      default: ''
    },
    minWidth: {
      type: String,
      default: '60px'
    },
    active: {
      type: Boolean,
      default: false
    },
    focusOnMount: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      focused: false,
      value: this.initialValue
    }
  },

  mounted () {
    if (this.focusOnMount) {
      this.$refs.input.focus()
    }
  },

  methods: {
    onClick () {
      this.$refs.input.focus()
    },

    onFocus () {
      this.focused = true
    },

    onBlur () {
      this.focused = false
      this.$emit('blur')
    },

    onInput (e) {
      this.$emit('change', e.target.innerText)
    },

    onKeyUp (e) {
      const ENTER = 13
      if (e.keyCode === ENTER) {
        const value = e.target.innerText.trim()
        e.target.innerText = value
        this.$emit('change', value)
        this.$refs.input.blur()
      }
    },

    onClose () {
      this.$emit('close')
    }
  }
}
</script>
