<template>
    <div :class="{
        'subscription-banner': true,
        'subscription-banner--subscribed': isSubscribed
    }">
        <p class="subscription-banner__icon">🦸🏻</p>
        <div class="subscription-banner__text-container">
            <p :class="{
                'subscription-banner__title': true,
                'subscription-banner__title--subscribed': isSubscribed
            }">
              <span v-if="isSubscribed">{{ $t('paymentBannerTitlePaid') }}</span>
              <span v-else>{{ $t('paymentBannerTitle') }}</span>
              <span v-if="isSubscribed" class="subscription-banner__until">{{ $t('paymentBannerTitleUntil') }} {{ subscriptionValidUntil }}</span>
            </p>
            <p :class="{
                'subscription-banner__subtitle': true,
                'subscription-banner__subtitle--subscribed': isSubscribed
            }">
              <span v-if="isSubscribed">{{ $t('paymentBannerTextPaid') }}</span>
              <span v-else>{{ $t('paymentBannerText') }}</span>
            </p>
        </div>
        <button v-if="!isSubscribed" :class="{
          'subscription-banner__button': true,
          'subscription-banner__button--settings': isSubscribed
        }" @click="onButtonClick">
          <span>{{ $t('paymentBannerButton') }}</span>
      </button>
    </div>
</template>

<style lang="scss" scoped>
    .subscription-banner {
        width: 100%;
        height: 45px;
        background: linear-gradient(269.79deg, #422354 -1.58%, #894CB0 99.49%);
        box-shadow: 0px 1px 0px #EAEAEA;
        border-radius: 0 0 8px 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 65px;
        padding-right: 14px;
        position: relative;

        &--subscribed {
            background: white;
        }
    }

    .subscription-banner__until {
      color: rgba(51, 51, 51, 0.5);
      font-size: 9px;
      font-weight: 400;
    }

    .subscription-banner__date {
        color: rgba(51, 51, 51, 0.5);
        font-size: 12px;
    }

    .subscription-banner__icon {
        position: absolute;
        left: 15px;
        bottom: -8px;
        font-size: 41px;
        text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.6);
    }

    .subscription-banner__title {
        color: white;
        font-size: 14px;
        font-weight: 700;

        &--subscribed {
            color:#333333;
        }
    }

    .subscription-banner__subtitle {
        color: white;
        font-size: 10px;

        &--subscribed {
            color:#333333;
        }
    }

    .subscription-banner__button {
        background: rgba(255, 255, 255, 0.2);
        padding: 5px 7px;
        opacity: 0.8;
        border-radius: 3px;
        color: white;
        font-weight: 700;
        font-size: 11px;
        transition: opacity 0.3s;

        &:hover {
            opacity: 1;
        }
    }

    .subscription-banner__button--settings {
      background: rgba(128, 128, 128, 0.2);
      color: rgba(6, 6, 6, 1);
    }
</style>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  methods: {
    onButtonClick () {
      if (this.isSubscribed) {
        this.showSubscriptionSettings()
      } else {
        this.subscribe()
      }
    },

    subscribe () {
      if (!this.user) {
        this.showLoginPopup()
      } else if (!this.user.isSubscribed) {
        this.showPaymentPopup()
      }
    },

    ...mapActions(['showLoginPopup', 'showPaymentPopup', 'showSubscriptionSettings'])
  },
  computed: {
    isSubscribed () {
      return this.user && this.user.isSubscribed
    },

    ...mapGetters(['user', 'subscriptionValidUntil'])
  }
}
</script>
