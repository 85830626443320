<template>
  <input class="avi-input" :readonly="disabled" @input="onChange" />
</template>

<style lang="scss" scoped>
  .avi-input {
    transition: .2s color;
    border: none;
    outline: none;
    background: none;
    outline: none;
    margin: 0;

    @media screen and (max-width: 640px) {
      font-size: 16px;
    }
  }
</style>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'text'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onChange (e) {
      this.$emit('change', e)
    }
  }
}
</script>
