<template>
    <div>
    <p class="subscription-layout__icon">🦸🏻</p>
      <p class="subscription-layout__title">
        <slot name="popup-title"></slot>
      </p>
      <p class="subscription-layout__text">
        {{ $t('paymentPopupText') }}
      </p>
      <br>
      <p class="subscription-layout__text">😡</p>
      <p class="subscription-layout__text">{{ $t('paymentPopupAngryText') }}</p>
      <br/>
      <p class="subscription-layout__text">💜</p>
      <p class="subscription-layout__text">{{ $t('paymentPopupHeartText') }}</p>
      <br/>
      <p class="subscription-layout__text">🏥</p>
      <p class="subscription-layout__text">{{ $t('paymentPopupHospitalText') }}</p>
      <slot></slot>
    </div>
</template>

<style lang="scss" scoped>
.subscription-layout__icon {
    font-size: 41px;
}

.subscription-layout__title {
    font-size: 24px;
    color: rgba(51, 51, 51, 1);
    font-weight: bold;
}

.subscription-layout__text {
    font-size: 12px;
    color: rgba(51, 51, 51, 1)
}

.subscription-layout__option {
    display: block;
    width: 100%;
    background: white;
    border: 1px solid rgba(51, 51, 51, 0.3);
    border-radius: 4px;
    padding: 8px;

    &:hover {
    cursor: pointer;
    }

    &:last-child {
    margin-left: 12px;
    }
}
</style>

<script>
export default {
}
</script>
