<template>
  <div class="readable-footer">
    <div class="readable-foooter__right-panel">
      <div class="readable-footer__inner-panel">
        <div class="readable-footer__price" v-if="offerOption.price">
          <p class="readable-footer__price-title readable-footer__mini-title">
            {{ $t('price') }}
          </p>
          <p class="readable-footer__price-price">
            {{ offerOption.price }}
          </p>
        </div>
        <div v-if="luggageSelected" class="readable-footer__luggage">
          <p
            :class="{
              'readable-footer__luggage-title': true,
              'readable-footer__mini-title': true,
              'readable-footer__mini-title--red': !offerOption.luggage,
            }"
          >
            <span v-if="offerOption.luggage">{{ $t('luggageIncluded') }}</span>
            <span v-else>{{ $t('onlyHandLuggage') }}</span>
          </p>
          <div>
            <img class="readable-footer__hand-luggage" src="./hand_luggage_ok.svg" />
            <img
              v-if="offerOption.luggage"
              src="./luggage_ok.svg"
            />
            <img
              v-else
              src="./luggage_no.svg"
            />
          </div>
        </div>
      </div>
      <div
        :class="{
          'readable-footer__tags-wrapper': true,
          'readable-footer__tags-wrapper--margin-mobile': (offerOption.price || luggageSelected) && offerOption.tags.length
        }"
      >
        <p
          :key="tag.tmpId"
          v-for="tag in offerOption.tags"
          class="readable-footer__tag"
        >
          {{ tag.name }}
        </p>
      </div>
    </div>
    <DetailsButton
      v-if="isDetailedView"
      :expandable-arrows="false"
      :title="$t('hide')"
      @click="onToggleDetailsView"
    >
    </DetailsButton>
    <DetailsButton
      v-else
      :expandable-arrows="true"
      :title="$t('details')"
      @click="onToggleDetailsView"
    >
    </DetailsButton>
  </div>
</template>

<style scoped lang="scss">
  .readable-foooter__right-panel {
    display: flex;

    @media screen and (max-width: 640px) {
      flex-direction: column;
    }
  }

  .readable-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(51, 51, 51, 0.1);
    padding: 10px 14px 10px 14px;
  }

  .readable-footer__mini-title {
    opacity: 0.9;
    font-size: 10px;
    color: #333;

    &--red {
      color: #ff194f;
    }
  }

  .readable-footer__price-price {
    font-size: 19px;
    color: #765bad;
    font-weight: bold;
  }

  .readable-footer__price {
    margin-right: 26px;
    flex-shrink: 0;
  }

  .readable-footer__luggage {
    flex-shrink: 0;
    margin-right: 26px;
  }

  .readable-footer__tag {
    margin-right: 6px;
    border-radius: 14px;
    border: solid 1px rgba(51, 51, 51, 0.15);
    padding: 2px 8px 4px;
    margin-bottom: 2px;
    margin-top: 2px;
    text-align: left;
    font-size: 11px;
    display: inline-block;
  }

  .readable-footer__inner-panel {
    display: flex;
    flex-shrink: 0;
  }

  .readable-footer__tags-wrapper {
    flex-shrink: 1;

    &--margin-mobile {
      @media screen and (max-width: 640px) {
        margin-top: 10px;
      }
    }
  }
</style>

<script>
import DetailsButton from '../DetailsButton'

export default {
  components: {
    DetailsButton
  },

  props: [
    'offerOption',
    'isDetailedView'
  ],

  methods: {
    onToggleDetailsView () {
      this.$emit('toggleDetailsView')
    }
  },

  computed: {
    luggageSelected () {
      return this.offerOption.luggage !== null
    }
  }
}
</script>
