<template>
        <VueGoodTable
            :columns="columns"
            :rows="rows"
            :search-options="{
                enabled: true
            }">
        </VueGoodTable>
</template>

<script>
import { getSubscriptions } from '../../api'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'

export default {
  components: {
    VueGoodTable
  },

  data () {
    return {
      columns: [
        {
          label: 'Email',
          field: 'email'
        },
        {
          label: 'Status',
          field: 'status'
        },
        {
          label: 'Created At',
          field: 'createdAt',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'yyyy-MM-dd'
        },
        {
          label: 'User ID',
          field: 'userId'
        },
        {
          label: 'Valid Until',
          field: 'validUntil',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'yyyy-MM-dd'
        }
      ],
      rows: []
    }
  },

  async mounted () {
    this.rows = (await getSubscriptions()).map(sub => ({
      ...sub,
      validUntil: moment(sub.validUntil).format('yyyy-MM-DD'),
      createdAt: moment(sub.createdAt).format('yyyy-MM-DD')
    }))
  }
}
</script>
