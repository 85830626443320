<template>
  <div class="preview">
    <div class="preview__container">
      <img class="preview__close" src="./images/cross.svg" @click="onClose" />
      <div class="preview__flex">
        <div class="preview__desktop">
          <p class="preview__header">{{ $t('onDesktop') }}</p>
          <iframe
            class="preview__desktop-iframe"
            width="641"
            height="632"
            frameborder="0"
            :src="`${host}/${offerId}?disablewrite=true&forceLang=${$i18n.locale}`"
          >
          </iframe>
        </div>
        <div class="preview__mobile">
          <p class="preview__header">{{ $t('onPhone') }}</p>
          <iframe
            class="preview__mobile-iframe"
            width="375"
            height="600"
            frameborder="0"
            :src="`${host}/${offerId}?disablewrite=true&forceLang=${$i18n.locale}`"
          >
          </iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .preview {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(51, 51, 51, 0.8);
    z-index: 10;
  }

  .preview__container {
    position: relative;
    margin: 37px 33px;
    width: calc(100% - 66px);
    height: calc(100vh - 74px);
    border-radius: 10px;
    background: #eee;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .preview__close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }

  .preview__flex {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    margin: auto;
    max-width: 1200px;
  }

  .preview__header {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin: 32px 0 32px;
  }

  .preview__desktop-iframe {
    margin: -32px 16px 0;
  }

  .preview-mobile {
    margin: 0 16px;
  }

  .preview__mobile-iframe {
    border-radius: 10px 10px 0 0;
  }
</style>

<script>
import { mapGetters } from 'vuex'
import { PROJECTS } from '../../../../constants'

export default {
  props: {
    offerId: {
      type: Object
    }
  },

  computed: {
    host () {
      return this.project.name === PROJECTS.AVIATO ? 'https://aviato.me' : 'https://flights.pnr'
    },

    ...mapGetters(['project'])
  },

  methods: {
    onClose () {
      this.$emit('close')
    }
  }
}
</script>
