import { formatDatetime, formatTime } from '/app/date-utils'
import { i18n } from '../../../../translations'
import { minutesToDuration } from '../../utils'
import moment from 'moment'

function formatWithDate (dateStr) {
  return formatDatetime(dateStr)
}

function formatWithoutDate (dateStr) {
  return formatTime(dateStr)
}

function segmentDates (segment) {
  const departureDate = new Date(segment.departure.timestamp)
  const arrivalDate = new Date(segment.arrival.timestamp)
  const departureDateText = formatWithDate(segment.departure.timestamp)
  const isMoreThan1day = (arrivalDate - departureDate) > 24 * 60 * 60 * 1000
  const arrivalDateText = (
    isMoreThan1day
      ? formatWithDate(segment.arrival.timestamp)
      : formatWithoutDate(segment.arrival.timestamp)
  )
  return `${departureDateText} – ${arrivalDateText}`
}

function airportToText (airport) {
  if (airport.city && airport.city !== airport.name) {
    return `${airport.city} (${airport.name})`
  }
  return airport.name || airport.code
}

function airlineToText (airline) {
  return airline.name || airline.code
}

function flightNo (segment) {
  return `${segment.airline.code} ${segment.number}`
}

function segmentToText (segment, language) {
  let base = `${segmentDates(segment)}, ${airportToText(segment.departure.airport)} — ${airportToText(segment.arrival.airport)}, ${flightNo(segment)}, ${airlineToText(segment.airline)}`
  if (segment.duration) {
    base += '. ' + minutesToDuration(segment.duration, language)
  }
  return base
}

function optionToText (option, language) {
  const segments = [
    ...option.toDestination,
    ...option.fromDestination
  ]
  const result = segments.map(x => segmentToText(x, language)).join('\n')
  const additionalText = []
  if (option.price) {
    additionalText.push(i18n.t('optionToTextPrice').replace('{number}', option.price))
  }
  if (option.luggage !== null) {
    if (option.luggage) {
      additionalText.push(i18n.t('offerTextWithLuggage'))
    } else {
      additionalText.push(i18n.t('offerTextWithoutLuggage'))
    }
  }
  if (option.tags.length > 0) {
    additionalText.push(option.tags.map(tag => tag.name).join(' | '))
  }
  return [result, additionalText.join(' | ')].join('\n')
}

const segmentToMachineText = (segment) => {
  const { departure, arrival } = segment
  const departureDate = moment(departure.timestamp).utc()
  const arrivalDate = moment(arrival.timestamp).utc()
  const departureDay = departureDate.format('DDMMM').toUpperCase()
  const departureTime = departureDate.format('HHmm')
  const arrivalTime = arrivalDate.format('HHmm')
  let res = `${segment.airline.code}${segment.number} ${departureDay} ${departure.airport.code}${arrival.airport.code} ${departureTime} ${arrivalTime}`
  if (arrivalDate.get('date') !== departureDate.get('date')) {
    res += '+1'
  }
  return res
}

const optionToMachineText = (option) => {
  return `${option.toDestination.map(segmentToMachineText).join('\n')}\n${option.fromDestination.map(segmentToMachineText).join('\n')}`
}

export function offerToText (offer, language) {
  const text = offer.name + '\n\n'
  const { options } = offer
  const optionsToText = []
  for (let i = 0; i < options.length; i++) {
    let optionText = ''
    const option = options[i]
    if (options.length > 1) {
      optionText += i18n.t('optionHelp').replace('{number}', i + 1) + '\n'
    }
    optionText += option.parsedByPnr ? optionToText(option, language) : optionToMachineText(option)
    optionsToText.push(optionText)
  }
  return text + optionsToText.join('\n\n')
}
