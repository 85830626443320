export const getVersion = () => {
  const scripts = document.querySelectorAll('script')
  for (const script of scripts) {
    const src = script.getAttribute('src')
    if (src && src.indexOf('client') > -1) {
      return src.split('.')[1] || ''
    }
  }
  return ''
}
