<template>
  <p class="offer-read-header">{{ name }}</p>
</template>

<style lang="scss" scoped>
  .offer-read-header {
    font-size: 24px;
    color: #333;
    font-weight: bold;
    padding: 0 4px;
  }
</style>

<script>
export default {
  props: {
    name: {
      type: String
    }
  }
}
</script>
