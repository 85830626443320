export const messages = {
  en: {
    installExtension: 'Install extension for Amadeus',
    installExtensionContent: 'Make offers and add options faster while not leaving Amadeus',
    installExtensionCTA: 'Install',

    optionToTextPrice: 'Price: {number}',
    inputHelp: 'Paste PNR from any GDS',
    optionHelp: 'Option no. {number}',
    luggageIncluded: 'Luggage included',
    onlyHandLuggage: 'Only hand luggage',
    myLabel: 'Custom label',
    createNewOffer: 'Create New Offer',
    clientView: 'How will the client see this?',
    linkCreated: 'Link created',
    savedMsg: 'All changes are saved',
    copiedMsg: 'Copied',
    copyText: 'Copy text',
    copyLink: 'Copy link',
    price: 'Price',
    aviPromo: 'Find cheap tickets for this option. You can earn the margin.',
    find: 'Search',
    aviNotFound: 'Haven\'t found the tickets. Try with other flight.',
    aviInProgress: 'Looking for tickets. Takes up to {seconds} seconds.',
    aviNoLuggageResults: 'Found cheap tickets without luggage for this option',
    aviOnlyLuggageResults: 'Found cheap tickets with luggage for this option',
    aviResults: 'Found cheap tickets for this option',
    aviNoLuggage: 'Without luggage',
    aviWithLuggage: 'With luggage',
    aviBuy: 'Buy for <span class="offer-option-tickets__price">{price} {currency}</span></span>',
    aviBuyLabeled: 'Buy for <span class="offer-option-tickets__price">{price} {currency}</span> on {{ label }}</span>',
    details: 'Details',
    hide: 'Hide',
    flightThere: 'Flight there',
    flightBack: 'Flight back',
    onDesktop: 'On Desktop',
    onPhone: 'On Phone',
    aviRedirectError: 'Something went wrong. We are sorry.',
    aviRedirectProgress: 'Redirecting you to the website. Please wait a few seconds.',
    messageVia: 'Message us via',
    consolidatorBanner: 'Earn more on air tickets',
    consolidatorLink: 'Put an ad on AVIATO.ME',
    version: 'Version: {version}',
    offerTextWithLuggage: 'Luggage included',
    offerTextWithoutLuggage: 'Only hand luggage',
    transferHoursMinutes: 'Transfer {hours} h. {minutes} m.',
    tutorialNewPnrTitle: 'Paster PNR of any GDS',
    tutorialNewPnrContent: 'Instantly create modern and informative offers for your clients. Just paste a PNR of any GDS.',
    tutorialPriceTitle: 'Add price and/or luggage',
    tutorialPriceContent: 'Use tags to quickly add info. All changes will be saved automatically.',
    tutorialLinkTitle: 'Copy your offer',
    tutorialLinkContent: 'You can copy it and send as a link or as a text',
    tutorialLinkCta: 'Next',
    tutorialNewOfferTitle: 'Create new offer',
    tutorialNewOfferContent: 'Once the offer for the client is ready, create a new one. Return to previously created offer just by following a link.',
    tutorialNewOfferCta: 'Got it',
    loginPopupTitle: 'Login',
    loginPopupHint: 'Sign up, it\'s free. It would not take more than 3 minutes. You will get unlimited convertions and new features.',
    loginPopupPlaceholder: 'Email',
    loginPopupCta: 'Send',
    loginPopupCancel: 'Cancel',
    loginPopupSent: 'Sent',
    loginPopupSentHint: 'Link to log in on AVIATO.me was sent to your email',
    changeLang: 'По-русски',
    loginOneMinute: 'One minute...',
    loginError: 'Error',
    optionsTo: 'Options to {airport}',
    cookies: 'We use <a target="_blank" class="global-wrapper__cookie-link" href="{cookieLink}">cookies</a> and <a class="global-wrapper__cookie-link" href="{gdprLink}" target="_blank">GDPR policy</a>',
    mainHeadline: 'Convert PNR to a passanger-friendly representation',
    mainSubHeadline: 'Paste PNR from any GDS & we\'ll convert it to a passanger-friendly view. Convert options in a second. Save your time.',
    warTitle: 'We are in Ukraine, at war',
    warHint: 'We will be back as soon as we can. Meanwhile, you can <a class="war-link" href="https://uahelp.monobank.ua/" target="_blank">help</a> to get us back on track faster.',

    paymentBannerTitle: 'Be a HERO',
    paymentBannerText: 'Subscribe to AVIATO.me and support Ukrainian nation',
    paymentBannerButton: 'Subscribe',
    paymentBannerDate: 'Subscribed until',
    paymentPopupCancel: 'Cancel',
    paymentPopupTitle: 'Be a HERO',
    paymentSettingsButton: 'Subscription settings',

    paymentPopupText: 'During 6 years AVIATO.me has been working with one purpose — to simplify aviamanagers\' lives. 6 months of work time — that is how much we saved you during these 6 years. ALl this time it was free. All this time we have developed and maintained the product with our own money. That could have continued forever.',
    paymentPopupAngryText: 'But at the start of 2022, the war came to our country. All our efforts are aimed to fight for life and freedom.',
    paymentPopupHeartText: 'Starting from 5th of Feb, 2023 AVIATO.me will become partly paid. If you are doing more than 5 convertions per day, you will be offered a subscription. With it you can make unlimited number of convertions. You will also get a possibility to suggest AVIATO improvements.',
    paymentPopupHospitalText: 'All income will be transfered to a volunteer aid in Ukraine. By subscribing you are helping Ukrainian nation to defend its right to exist.',
    paymentPopupMontly: 'Monthly subscription',
    paymentPopupMontlyTitle: 'month',
    paymentPopupMontlyHelp: 'Monthly payment',
    paymentPopupYearly: 'Yearly subscription',
    paymentPopupYearlyHelp: 'Yearly payment',
    paymentPopupCta: 'Subscribe',

    paymentFormCardHolder: 'Card Holder',
    paymentFormFullName: 'Full Name',
    paymentFormCardDate: 'Card Date',
    paymentFormMM: 'MM',
    paymentFormYY: 'YY',
    paymentFormExpirationDate: 'Expires',
    paymentFormDate: 'Day',
    paymentFormYear: 'Year',
    paymentFormSubmit: 'Submit',
    paymentFormCardNumber: 'Card Number',
    paymentFormLoading: 'Processing transaction...',
    paymentErrorHappened: 'Some error occured, please contact us to resolve it',

    subscriptionSettingsPopupTitle: 'You are HERO',
    subscriptionSettingsUntil: 'until',
    subscriptionSettingsUnsubscribe: 'Unsubscribe',
    unsubscribeTitle: 'We are very sorry',
    unsubscribeText: 'You can continue using service until your subscription expires. After this we\'ll transfer you to a free tier.',
    unsubscribeButtonText: 'Yes, I want to unsubscribe',

    loginPopupMessage: 'You need to log in in order to continue using AVIATO',

    paymentBannerTitlePaid: 'You are HERO',
    paymentBannerTextPaid: 'You help AVIATO.me and support Ukranian nation',
    paymentBannerTitleUntil: 'until',

    thankYouPopupTitle: 'Thank you for support',
    thankYouPopupMessage: 'Your subscription is valid until {validUntil}. If you have any questions or feedback, you can reach directly Artem – akilmyashkin@gmail.com.',
    thankYouPopupOkay: 'OK',
    logout: 'Logout',
    login: 'Login'
  },
  ru: {
    installExtension: 'Установи расширение для Amadeus',
    installExtensionContent: 'Делай предложения и добавляй варианты быстрее не покидая Amadeus',
    installExtensionCTA: 'Установить',

    optionToTextPrice: 'Цена: {number}',
    inputHelp: 'Вставьте PNR из любой GDS',
    optionHelp: 'Вариант №{number}',
    luggageIncluded: 'Багаж включён',
    onlyHandLuggage: 'Только ручная кладь',
    myLabel: 'Своя метка',
    createNewOffer: 'Создать новое предложение',
    clientView: 'Как будет выглядеть у клиента?',
    linkCreated: 'Ссылка создана',
    savedMsg: 'Все изменения сохранены',
    copiedMsg: 'Скопирован',
    copyText: 'Копировать текстом',
    copyLink: 'Копировать ссылку',
    price: 'Цена',
    aviPromo: 'Найдём дешёвые билеты по этому варианту. Заработаете на разнице.',
    find: 'Найти',
    aviNotFound: 'Не нашли подходящих билетов. Попрубуйте на другом варианте.',
    aviInProgress: 'Ищем билеты. Занимает до {seconds} секунд.',
    aviNoLuggageResults: 'Нашли дешёвые билеты без багажа по этому варианту',
    aviOnlyLuggageResults: 'Нашли дешёвые билеты с багажом по этому варианту',
    aviResults: 'Нашли дешёвые билеты по этому варианту',
    aviNoLuggage: 'Без багажа',
    aviWithLuggage: 'С багажом',
    aviBuy: 'Купить за <span class="offer-option-tickets__price">{price} {currency}</span></span>',
    aviBuyLabeled: 'Купить за <span class="offer-option-tickets__price">{price} {currency}</span> на {{ label }}</span>',
    details: 'Подробнее',
    hide: 'Скрыть',
    flightThere: 'Перелёт туда',
    flightBack: 'Перелёт обратно',
    onDesktop: 'На компьютере',
    onPhone: 'На смартфоне',
    aviRedirectError: 'Что-то пошло не так. Извините.',
    aviRedirectProgress: 'Переходим на сайт. Пожалуйста, подождите несколько секунд.',
    messageVia: 'Пишите нам на',
    consolidatorBanner: 'Зарабатывайте на продаже авиабилетов больше',
    consolidatorLink: 'Заказать рекламу на AVIATO.me',
    version: 'Сборка: {version}',
    offerTextWithLuggage: 'Багаж включен',
    offerTextWithoutLuggage: 'Только ручная кладь',
    transferHours: 'Пересадка {hours} ч.',
    transferHoursMinutes: 'Пересадка {hours} ч. {minutes} м.',
    tutorialNewPnrTitle: 'Вставьте PNR любой GDS',
    tutorialNewPnrContent: 'Мгновенно создавайте современные и информативные предложения для клиентов, просто вставляя PNR любой GDS.',
    tutorialPriceTitle: 'Добавьте цену и багаж',
    tutorialPriceContent: 'Используйте метки для быстрого добавления информации. Все изменения сохраняются автоматически.',
    tutorialLinkTitle: 'Скопируйте ваше предложение',
    tutorialLinkContent: 'Вы можете скопировать и отправить предложение ссылкой или текстом.',
    tutorialLinkCta: 'Дальше',
    tutorialNewOfferTitle: 'Создайте новое предложение',
    tutorialNewOfferContent: 'Когда предложение для клиента готово, создайте новое. Возвращайтесь к ранее созданному предложению просто перейдя по ссылке.',
    tutorialNewOfferCta: 'Ясно',
    loginPopupTitle: 'Войти',
    loginPopupHint: 'Зарегестрируйтесь, это бесплатно и займёт не более 3х минут. Будет доступно неограниченное количество конвертаций и новый функционал',
    loginPopupPlaceholder: 'Электронная почта',
    loginPopupCta: 'Отправить',
    loginPopupCancel: 'Отмена',
    loginPopupSent: 'Отправлено',
    loginPopupSentHint: 'На вашу почту отправлена ссылка с входом на AVIATO.me',
    changeLang: 'In English',
    loginOneMinute: 'Минутку...',
    loginError: 'Ошибка',
    optionsTo: 'Варианты в {airport}',
    cookies: 'Мы используем <a target="_blank" class="global-wrapper__cookie-link" href="{cookieLink}">куки</a> и <a class="global-wrapper__cookie-link" href="{gdprLink}" target="_blank">GDPR политику</a>',
    mainHeadline: 'Конвертируй PNR в понятный вид для пассажира',
    mainSubHeadline: 'Вставь PNR из любой GDS, мы сконвертируем его в понятный вид для пассажира. Конвертируй варианты за секунды. Экономь своё время.',
    warTitle: 'Мы в Украине, у нас война',
    warHint: 'Мы вернемся как только она закончится. Пока что вы можете <a class="war-link" href="https://uahelp.monobank.ua/" target="_blank">помочь</a> ускорить этот процесс.',

    paymentBannerTitle: 'Будь HERO',
    paymentBannerText: 'Оформи подписку AVIATO.me и поддержи украинский народ',
    paymentBannerButton: 'Оформить подписку',
    paymentBannerDate: 'Действительна до',
    paymentPopupCancel: 'Отмена',
    paymentPopupTitle: 'Будь HERO',
    paymentPopupText: '6 лет AVIATO работал с единой целью — упрощать жизнь авиаменеджеров. Полгода рабочего времени — столько времени мы сэкономили за 6 лет. Всё это время он был бесплатным. Всё это время мы развивали и поддерживали продукт за собственный счёт. И так бы, скорее всего, продолжалось бы всегда.',
    paymentPopupAngryText: 'Но в начале 2022 года в нашу страну пришла война, все наши усилия направлены на борьбу за свою жизнь и свободу.',
    paymentPopupHeartText: 'Начиная с 5 февраля 2023 AVIATO.me станет частично платным. Если вы делаете больше 5 конвертаций в день вам буде предложена подписка. С ней вы сможете делать неограниченное количество конвертаций и получите возможность давать рекомендаций для улучшения AVIATO.',
    paymentPopupHospitalText: 'Вся прибыль пойдет на волонтерскую помощь Украине. Оформляя подписку, вы помогаете украинскому народу отстоять своя право на существование.',
    paymentPopupMontly: 'Месячная подписка',
    paymentPopupMontlyTitle: 'мес',
    paymentPopupMontlyHelp: 'Оплата за месяц',
    paymentPopupYearly: 'Годовая подписка',
    paymentPopupYearlyHelp: 'Оплата за год',
    paymentPopupCta: 'Оплатить',
    paymentSettingsButton: 'Настройки подписки',

    paymentFormCardHolder: 'Имя на карте',
    paymentFormFullName: 'Полное имя',
    paymentFormCardDate: 'Срок',
    paymentFormMM: 'ДД',
    paymentFormYY: 'ГГ',
    paymentFormExpirationDate: 'Срок',
    paymentFormDate: 'День',
    paymentFormYear: 'Год',
    paymentFormSubmit: 'Оплатить',
    paymentFormCardNumber: 'Номер карты',
    paymentFormLoading: 'Происходит оплата...',
    paymentErrorHappened: 'Произошла ошибка, пожалуйста, напишите нам, чтобы мы могли вам помочь',

    subscriptionSettingsPopupTitle: 'Ты HERO',
    subscriptionSettingsUntil: 'до',
    subscriptionSettingsUnsubscribe: 'Отписаться',
    unsubscribeTitle: 'Нам очень жаль',
    unsubscribeText: 'Вы можете продолжать пользоваться сервисом до конца срока действия своей подписки. После этого вы перейдете на обычную версию.',
    unsubscribeButtonText: 'Да, я хочу отписаться',

    loginPopupMessage: 'Для того, чтобы продолжать использовать AVIATO.me, необходимо войти',

    paymentBannerTitlePaid: 'Ты HERO',
    paymentBannerTextPaid: 'Ты помогаешь AVIATO.me и поддерживаешь украинский народ',
    paymentBannerTitleUntil: 'до',

    thankYouPopupTitle: 'Спасибо за поддержку',
    thankYouPopupMessage: 'Ваша подписка действительна до {validUntil}. Если у вас возникнут вопросы или замечания, вы можете обратиться напрямую к Артёму – akilmyashkin@gmail.com.',
    thankYouPopupOkay: 'Хорошо',
    logout: 'Выйти с профиля',
    login: 'Войти в профиль'
  },
  fr: {
    installExtension: 'Installer l\'extension pour Amadeus',
    installExtensionContent: 'Faites des offres et ajoutez des options plus rapidement sans quitter Amadeus',
    installExtensionCTA: 'Installer',

    optionToTextPrice: 'Prix: {number}',
    inputHelp: "Insérez PNR depuis n'importe quel GDS",
    optionHelp: 'variante N{number}',
    luggageIncluded: 'bagage inclus',
    onlyHandLuggage: 'seulement bagage cabine',
    myLabel: 'ma marque',
    createNewOffer: 'créer une nouvelle offre',
    clientView: 'vue client',
    linkCreated: 'lien crée',
    savedMsg: 'tous les changements sauvegardés',
    copiedMsg: 'copié',
    copyText: 'copier le texte',
    copyLink: 'copier le lien',
    price: 'prix',
    aviPromo: 'Nous trouverons des billets bon marché. Vous gagnerez sur la différence des prix.',
    find: 'trouver',
    aviNotFound: 'Nous n’avons pas trouvé de billets convenables. Essayez une autre combinaison.',
    aviInProgress: 'Nous cherchons les billets. Cela prendra jusqu’à {seconds} secondes.',
    aviNoLuggageResults: 'Nous avons trouvé des billets bon marché sans bagages dans cette combinaison.',
    aviOnlyLuggageResults: ' Nous avons trouvé des billets bon marché avec bagages dans cette combinaison.',
    aviResults: ' Nous avons trouvé des billets bon marché dans cette combinaison.',
    aviNoLuggage: 'Без багажа',
    aviWithLuggage: 'С багажом',
    aviBuy: 'acheter pour<span class="offer-option-tickets__price">{price} {currency}</span></span>',
    aviBuyLabeled: 'acheter pour<span class="offer-option-tickets__price">{price} {currency}</span> sur {{ label }}</span>',
    details: 'plus en détails',
    hide: 'cacher',
    flightThere: 'vol aller',
    flightBack: 'vol retour',
    onDesktop: 'sur l’écran d’ordinateur',
    onPhone: 'sur smartphone',
    aviRedirectError: 'Message d’erreur. Nos excuses.',
    aviRedirectProgress: 'Renvoi site. Veuillez attendre quelques secondes.',
    messageVia: 'veuillez nous contacter ici',
    version: 'assemblage: {version}',
    offerTextWithLuggage: 'bagage inclus',
    offerTextWithoutLuggage: 'seulement bagage cabine',
    transferHours: 'correspondance {hours} heures.',
    transferHoursMinutes: 'correspondance {hours} heures. {minutes} minutes.',
    tutorialNewPnrTitle: 'insérez le PNR de n’importe quel GDS',
    tutorialNewPnrContent: 'Créez instantanément des nouvelles propositions pour les clients, en entrant le PNR de n’importe quel GDS.',
    tutorialPriceTitle: 'ajoutez le prix et le bagage',
    tutorialPriceContent: 'Utilisez les marques pour l’ajout rapide de l’information. Tous les changements sont sauvegardés automatiquement.',
    tutorialLinkTitle: 'une proposition – un lien.',
    tutorialLinkContent: 'Chaque proposition avec les variantes de connexions pour le passager est un lien. Vous pouvez y entrer des nouvelles variantes sans créer un nouveau lien, et le client verra les mises à jour en ligne. Envoyez le lien au client, et proposez des nouvelles variantes directement dedans',
    tutorialLinkCta: ' à suivre',
    tutorialNewOfferTitle: 'créez une nouvelle proposition',
    tutorialNewOfferContent: 'Quand la proposition pour le client est prête, créez-un une nouvelle. Revenez vers la version précédente en suivant le lien.',
    tutorialNewOfferCta: 'c’est clair',
    loginPopupTitle: 'entrer',
    loginPopupHint: 'Recevez un nombre illimités de conversions',
    loginPopupPlaceholder: 'courrier électronique',
    loginPopupCta: 'envoyer',
    loginPopupCancel: 'annuler',
    loginPopupSent: 'envoyé',
    loginPopupSentHint: 'Vous avez reçu par courrier électronique un lien avec l’entrée sur AVIATO.me',
    changeLang: 'In English',
    loginOneMinute: 'un moment svp.',
    loginError: 'erreur',
    optionsTo: 'combinaisons vers {airport}',
    landingTitle: 'convertis le PNR dans une version compréhensible pour le passager',
    landingText: 'Entre le PNR depuis n’importe quel GDS, nous le convertirons dans une version compréhensible pour le passager. Convertis les variantes par secondes. Economise ton temps.',
    landingCta: 'Entre le PNR depuis n’importe quel GDS',
    tipHeader: 'Copiez votre proposition',
    tipText: 'Vous pouvez copier et envoyer la proposition par lien ou par texte.',
    tipNext: 'à suivre',
    newLoginTitle: 'éntrée',
    newLoginText: 'Après l’entrée des nouvelles fonctions seront disponibles',
    gdprCookie: 'Nous utilisons les cookies et la politique RGPD',
    gdprCta: 'ОК',
    cookies: 'Nous utilisons <a target="_blank" class="global-wrapper__cookie-link" href="{cookieLink}">des cookies</a> et <a class="global-wrapper__cookie-link" href="{gdprLink}" target="_blank">la politique du GDPR</a>',
    mainHeadline: 'Convertissez le PNR en une représentation compréhensible pour les passagers',
    mainSubHeadline: "Collez le PNR de n'importe quel GDS et nous le convertirons en une représentation compréhensible pour les passagers. Convertissez les options en une seconde. Gagnez du temps.",
    warTitle: 'We are in Ukraine, at war',
    warHint: 'We will be back as soon as we can. Meanwhile, you can <a class="war-link" href="https://uahelp.monobank.ua/" target="_blank">help</a> to get us back on track faster.',

    paymentBannerTitlePaid: 'You are HERO',
    paymentBannerTextPaid: 'You help AVIATO.me and support Ukranian nation',
    paymentBannerTitleUntil: 'until',
    logout: 'Logout',
    login: 'Login'
  },
  uk: {
    installExtension: 'Встанови розширення для Amadeus',
    installExtensionContent: 'Роби пропозиції і додавай варіанти швидше не покидаючи Amadeus',
    installExtensionCTA: 'Встановити',

    optionToTextPrice: 'Ціна: {number}',
    inputHelp: 'Вставте PNR з будь-якої GDS',
    optionHelp: 'Варіант №{number}',
    luggageIncluded: 'Багаж включено',
    onlyHandLuggage: 'Тільки ручна поклажа',
    myLabel: 'Своя мітка',
    createNewOffer: 'Створити нову пропозицію',
    clientView: 'Як буде виглядати у клієнта?',
    linkCreated: 'Посилання створено',
    savedMsg: 'Всі зміни збережено',
    copiedMsg: 'Скопійовано',
    copyText: 'Копіювати текстом',
    copyLink: 'Копіювати посилання',
    price: 'Ціна',
    aviPromo: 'Знайдемо дешеві квитки по цьому варіанту. Заробите на різниці. ',
    find: 'Знайти',
    aviNotFound: 'Не знайшли підходящих квитків. Спробуйте на іншому варіанті.',
    aviInProgress: 'Шукаємо квитки. Займає до {seconds} секунд.',
    aviNoLuggageResults: 'Знайшли дешеві квитки без багажа по цьому варіанту',
    aviOnlyLuggageResults: 'Знайшли дешеві квитки з багажом по цьому варіанту',
    aviResults: 'Знайшли дешеві квитки по цьому варіанту',
    aviNoLuggage: 'Без багажу',
    aviWithLuggage: 'З багажем',
    aviBuy: 'Купити за <span class="offer-option-tickets__price">{price} {currency}</span></span>',
    aviBuyLabeled: 'Купити за <span class="offer-option-tickets__price">{price} {currency}</span> на {{ label }}</span>',
    details: 'Детальніше',
    hide: 'Сховати',
    flightThere: 'Переліт туди',
    flightBack: 'Переліт назад',
    onDesktop: 'На комп\'ютері',
    onPhone: 'На смартфоні',
    aviRedirectError: 'Щось пішло не так. Просимо вибачення.',
    aviRedirectProgress: 'Переходимо на сайт. Будь ласка, почекайте декілька секунд.',
    messageVia: 'Пишіть нам на',
    consolidatorBanner: 'Заробляйте на продажі авіаквитків більше',
    consolidatorLink: 'Замовити рекламу на AVIATO.me',
    version: 'Білд: {version}',
    offerTextWithLuggage: 'Багаж включено',
    offerTextWithoutLuggage: 'Тільки ручна поклажа',
    transferHours: 'Пересадка {hours} г.',
    transferHoursMinutes: 'Пересадка {hours} г. {minutes} хв.',
    tutorialNewPnrTitle: 'Вставте PNR будь-якої GDS',
    tutorialNewPnrContent: 'Моментально створюйте сучасні і інформативні пропозиції для клієнтів, просто вставляючи PNR будь-якої GDS.',
    tutorialPriceTitle: 'Додати ціну і багаж',
    tutorialPriceContent: 'Використовуйте мітки для швидкого додавання інформації. Всі зміни зберігаються автоматично.',
    tutorialLinkTitle: 'Скопіюйте вашу пропозицію',
    tutorialLinkContent: 'Ви можете скопіювати і відправити пропозицію посиланням або текстом.',
    tutorialLinkCta: 'Далі',
    tutorialNewOfferTitle: 'Сторіть нову пропозицію',
    tutorialNewOfferContent: 'Коли пропозиція для клієнта готова, створіть нову. Повертайтесь до попередньої пропозиції просто перейдучи за посиланням.',
    tutorialNewOfferCta: 'Зрозуміло',
    loginPopupTitle: 'Увійти',
    loginPopupHint: 'Зареєструйтесь, це безкоштовно і займе не більше 3х хвилин. Буде доступне необмежене число конвертацій і нова функіональність',
    loginPopupPlaceholder: 'Електронна пошта',
    loginPopupCta: 'Надіслати',
    loginPopupCancel: 'Відміна',
    loginPopupSent: 'Надіслано',
    loginPopupSentHint: 'На вашу пошту надіслано посилання з входом на AVIATO.me',
    changeLang: 'In English',
    loginOneMinute: 'Хвилинку...',
    loginError: 'Помилка',
    optionsTo: 'Варіанти в {airport}',
    cookies: 'Ми використовуємо <a target="_blank" class="global-wrapper__cookie-link" href="{cookieLink}">ріпʼяхи</a> і <a class="global-wrapper__cookie-link" href="{gdprLink}" target="_blank">GDPR політику</a>',
    mainHeadline: 'Конвертуйте PNR в зрозумілий вигляд для пасажира',
    mainSubHeadline: 'Вставте PNR з будь-якої GDS, ми сконвертуємо його в зрозумілий вид для пасажира. Конвертуйте варіанти за секунди. Економте свій час.',
    warTitle: 'Ми в Україні, у нас війна',
    warHint: 'Ми повернемось як тільки вона закінчиться. Поки ви можете <a class="war-link" href="https://uahelp.monobank.ua/" target="_blank">допомогти</a> пришвидшити цей процес.',

    paymentBannerTitle: 'Будь HERO',
    paymentBannerText: 'Оформи підписку AVIATO.me та підтримай український народ',
    paymentBannerButton: 'Оформити підписку',
    paymentBannerDate: 'Дійсна до',
    paymentPopupCancel: 'Відміна',
    paymentPopupTitle: 'Будь HERO',
    paymentPopupText: '6 років AVIATO працював з єдиною метою — спрощувати життя авіаменеджерам. Пів року рабочогу часу — стільки часу ми зекономили вам за 6 років. Весь цей час він був безкоштовним. Весь цей час ми розвивали і підтримували продукт за власний рахунок. І так би скоріше за все продовжувалось завжди.',
    paymentPopupAngryText: 'Але на початку 2022 року в нашу країну прийшла війна, всі наші зусилля направлені на боротьбу за життя та свободу.',
    paymentPopupHeartText: 'Починаючи з 5 лютого 2023 AVIATO.me стане частково платним. Якщо ви робите більше 5 конвертацій в день вам буде запропонована підписка. З нею ви зможете робити необмежену кількість конвертацій та отримаєте можливість давати рекомендації щодо покращень в AVIATO.',
    paymentPopupHospitalText: 'Весь прибуток піде на волонтерську допомогу Україні. Оформлюючи підписку ви допомагаєте українському народові відстояти своє право на існування.',
    paymentPopupMontly: 'Місячна підписка',
    paymentPopupMontlyTitle: 'міс',
    paymentPopupMontlyHelp: 'Оплата за місяць',
    paymentPopupYearly: 'Річна підписка',
    paymentPopupYearlyHelp: 'Оплата за рік',
    paymentPopupCta: 'Оплатити',
    paymentSettingsButton: 'Налаштування підписки',

    paymentFormCardHolder: 'Імʼя на картці',
    paymentFormFullName: 'Повне імʼя',
    paymentFormCardDate: 'Термін',
    paymentFormMM: 'ДД',
    paymentFormYY: 'РР',
    paymentFormExpirationDate: 'Термін',
    paymentFormDate: 'День',
    paymentFormYear: 'Рік',
    paymentFormSubmit: 'Оплатити',
    paymentFormCardNumber: 'Номер карти',
    paymentFormLoading: 'Триває оплата...',
    paymentErrorHappened: 'Виникла помилка, будь ласка, напишіть нам, щоб ми могли вам допомогти',

    subscriptionSettingsPopupTitle: 'Ти HERO',
    subscriptionSettingsUntil: 'до',
    subscriptionSettingsUnsubscribe: 'Відписатися',
    unsubscribeTitle: 'Нам дуже прикро',
    unsubscribeText: 'Ви можете користуватися сервісом до кінця терміну дії своєї підписки. Після цього ви перейдите на звичайну версію.',
    unsubscribeButtonText: 'Так, я хочу відписатися',

    loginPopupMessage: 'Для продовження користування AVIATO.me потрібно увійти',

    paymentBannerTitlePaid: 'Ти HERO',
    paymentBannerTextPaid: 'Ти допомогаєш AVIATO.me та підтримуєш український народ',
    paymentBannerTitleUntil: 'до',

    thankYouPopupTitle: 'Дякую за підтримку',
    thankYouPopupMessage: 'Ваша підписка дійсна до {validUntil}. Якщо у вас виникнуть запитання чи зауваження ви можете звертатись напряму до Артема – akilmyashkin@gmail.com.',
    thankYouPopupOkay: 'Добре',
    logout: 'Вийти з профілю',
    login: 'Увійти в профіль'
  },
  es: {
    installExtension: 'Instalar extensión para Amadeus',
    installExtensionContent: 'Haz ofertas y añade opciones más rápido sin salir de Amadeus',
    installExtensionCTA: 'Instalar',

    optionToTextPrice: 'Precio: {number}',
    inputHelp: 'Copia y pega el PNR de cualquier GDS',
    optionHelp: 'Opción no. {number}',
    luggageIncluded: 'Equipaje incluido',
    onlyHandLuggage: 'Solo equipaje de mano',
    myLabel: 'Etiqueta personalizada',
    createNewOffer: 'Crear nueva oferta',
    clientView: '¿Cómo verá esto el cliente?',
    linkCreated: 'Link creado',
    savedMsg: 'Todos los cambios han sido guardados',
    copiedMsg: 'Copiado',
    copyText: 'Copiar texto',
    copyLink: 'Copiar link',
    price: 'Precio',
    aviPromo: 'Encuentra billetes baratos para esta opción. Puedes ganarte el margen.',
    find: 'Buscar',
    aviNotFound: 'No encontramos los tiquetes. Intenta con otro vuelo.',
    aviInProgress: 'Buscando tiquetes. Tomará máximo {seconds} segundos.',
    aviNoLuggageResults: 'Encontramos tiquetes baratos sin equipaje para esta opción',
    aviOnlyLuggageResults: 'Encontramos tiquetes baratos con equipaje para esta opción',
    aviResults: 'Encontramos tiquetes baratos para esta opción',
    aviNoLuggage: 'Sin equipaje',
    aviWithLuggage: 'Con equipaje',
    aviBuy: 'Comprar por <span class="offer-option-tickets__price">{price} {currency}</span></span>',
    aviBuyLabeled: 'Comprar por <span class="offer-option-tickets__price">{price} {currency}</span> on {{ label }}</span>',
    details: 'Detalles',
    hide: 'Esconder',
    flightThere: 'Origen',
    flightBack: 'Destino',
    onDesktop: 'En ordenador',
    onPhone: 'En móvil',
    aviRedirectError: 'Algo salió mal. Lo sentimos.',
    aviRedirectProgress: 'Te estamos redirigiendo a la página. Por favor espera unos segundos.',
    messageVia: 'Envíanos un mensaje a través de',
    consolidatorBanner: 'Gana más en tiquetes áereos',
    consolidatorLink: 'Pon una pauta publicitaria en AVIATO.ME',
    version: 'Versión: {version}',
    offerTextWithLuggage: 'Equipaje incluido',
    offerTextWithoutLuggage: 'Solo equipaje de mano',
    transferHoursMinutes: 'Transfiere {hours} h. {minutes} m.',
    tutorialNewPnrTitle: 'Copia y pega el PNR de cualquier GDS',
    tutorialNewPnrContent: 'Crea instantaneamente ofertas modernas e informativas para tus clientes. Solo tienes que copiar y pegar el PNR de cualquier GDS.',
    tutorialPriceTitle: 'Añadir precio y/o equipaje',
    tutorialPriceContent: 'Usa etiquetas para añadir información rápidamente. Todos los cambios serán salvados de forma automática.',
    tutorialLinkTitle: 'Copia tu oferta',
    tutorialLinkContent: 'Puedes copiarla o enviarla como link o como texto',
    tutorialLinkCta: 'Siguiente',
    tutorialNewOfferTitle: 'Crear nueva oferta',
    tutorialNewOfferContent: 'Una vez la oferta para el cliente esté lista, crea una nueva. Regresa a la oferta anterior con tan solo hacer clic en un link.',
    tutorialNewOfferCta: 'Entendido',
    loginPopupTitle: 'Inicia sesión',
    loginPopupHint: 'Regístrate, es gratis. Te tomará menos de 3 minutos. Tendrás conversiones ilimitadas y nuevas funcionalidades.',
    loginPopupPlaceholder: 'Correo electrónico',
    loginPopupCta: 'Enviar',
    loginPopupCancel: 'Cancelar',
    loginPopupSent: 'Enviado',
    loginPopupSentHint: 'El link para iniciar sesión en AVIATO.ME ha sido enviado a tu correo',
    changeLang: 'En ruso',
    loginOneMinute: 'Un minuto...',
    loginError: 'Error',
    optionsTo: 'Opciones a {airport}',
    cookies: 'Usamos <a target="_blank" class="global-wrapper__cookie-link" href="{cookieLink}">cookies</a> y <a class="global-wrapper__cookie-link" href="{gdprLink}" target="_blank"> política de GDPR</a>',
    mainHeadline: 'Convierte un PNR a un formato amigable para el pasajero',
    mainSubHeadline: 'Copia y pega el PNR de cualquier GDS y lo convertiremos a un formato amigable para el pasajero. Convierte PNRs de forma instantánea. Ahorra tiempo.',
    warTitle: 'Estamos en Ucrania, en guerra',
    warHint: 'Estaremos de vuelta tan pronto como sea posible. Mientras tanto, puedes <a class="war-link" href="https://uahelp.monobank.ua/" target="_blank">ayudarnos</a> a estar de vuelta más rápido.',
    paymentBannerTitle: 'Sé un HEROE',
    paymentBannerText: 'Suscríbete a AVIATO.ME y apoya a Ucrania',
    paymentBannerButton: 'Suscríbete',
    paymentBannerDate: 'Suscrito hasta',
    paymentPopupCancel: 'Cancelar',
    paymentPopupTitle: 'Sé un HEROE',
    paymentSettingsButton: 'Configuración de la suscripción',
    paymentPopupText: 'Durante 6 años AVIATO.me ha trabajado con un propósito — simplificar la vida de quienes trabajan en la industria de la aviación. 6 meses de tiempo de trabajo — eso es lo que os hemos ahorrado durante estos 6 años. Todo este tiempo, nuestro servicio ha sido gratuito. Hemos desarrollado y mantenido nuestro producto con nuestro dinero. Esta situación podría haber continuado para siempre.',
    paymentPopupAngryText: 'Pero a comienzos del 2022, la guerra llegó a nuestro país. Todos nuestros esfuerzos están puestos en luchar por nuestra vida y nuestra libertad.',
    paymentPopupHeartText: 'Desde el 5 de febrero de 2023, AVIATO.ME tendrá una versión paga. Si haces más de 5 conversiones por día, te ofreceremos una suscripción. Con esta podrás hacer conversiones ilimitadas. También tendrás la posibilidad de sugerir mejoras al equipo de AVIATO.',
    paymentPopupHospitalText: 'Todas las utilidades serán donadas a ayudas humanitarias en Ucrania. Al suscribirte, estás ayudando a Ucrania a defender su derecho a existir.',
    paymentPopupMontly: 'Suscripción mensual',
    paymentPopupMontlyTitle: 'mes',
    paymentPopupMontlyHelp: 'Pago mensual',
    paymentPopupYearly: 'Suscripción anual',
    paymentPopupYearlyHelp: 'Pago anual',
    paymentPopupCta: 'Suscríbete',
    paymentFormCardHolder: 'Titular de la tarjeta',
    paymentFormFullName: 'Nombre completo',
    paymentFormCardDate: 'Fecha de vencimiento',
    paymentFormMM: 'MM',
    paymentFormYY: 'AA',
    paymentFormExpirationDate: 'Vence',
    paymentFormDate: 'Día',
    paymentFormYear: 'Año',
    paymentFormSubmit: 'Enviar',
    paymentFormCardNumber: 'Número de la tarjeta',
    paymentFormLoading: 'Procesando la transacción',
    paymentErrorHappened: 'Ha ocurrido un error, por favor contáctanos para resolverlo',
    subscriptionSettingsPopupTitle: 'Tú eres un HEROE',
    subscriptionSettingsUntil: 'hasta',
    subscriptionSettingsUnsubscribe: 'Cancelar la suscripción',
    unsubscribeTitle: 'Lo sentimos mucho',
    unsubscribeText: 'Puedes continuar usando el servicio hasta que finalice tu suscripción. Después de esta fecha, te transferiremos a la versión gratuita.',
    unsubscribeButtonText: 'Sí, quiero cancelar mi suscripción.',
    loginPopupMessage: 'Debes iniciar sesión para seguir usando AVIATO',
    paymentBannerTitlePaid: 'Tú eres un HEROE',
    paymentBannerTextPaid: 'Tú ayudas a AVIATO.ME y apoyas a Ucrania.',
    paymentBannerTitleUntil: 'hasta',
    thankYouPopupTitle: 'Gracias por tu ayuda',
    thankYouPopupMessage: 'Tu suscripción es válida hasta {validUntil}. Si tienes preguntas o sugerencias, puedes contactar directamente a Artem al correo akilmyashkin@gmail.com.',
    thankYouPopupOkay: 'OK',
    logout: 'Cerrar sesión',
    login: 'Iniciar sesión'
  },
  kz: {
    installExtension: 'Amadeus кеңейтімін орнатыңыз',
    installExtensionContent: 'Amadeus-тен кетпей, ұсыныстарды тезірек енгізіңіз және опцияларды қосыңыз',
    installExtensionCTA: 'Орнату',

    optionToTextPrice: 'Бағасы : {number}',
    inputHelp: 'Кез-келген GDS-тен PNR салыңыз',
    optionHelp: 'Нұсқа № {number}',
    luggageIncluded: 'Багаж кіреді',
    onlyHandLuggage: 'Тек қол жүгі',
    myLabel: 'Өз белгісі',
    createNewOffer: 'Жаңа ұсыныс жасау',
    clientView: 'Клиенте қалай көрінеді?',
    linkCreated: 'Сілтеме жасалды',
    savedMsg: 'Барлық өзгерістер сақталды',
    copiedMsg: 'Көшірілді',
    copyText: 'Мәтінмен көшіру',
    copyLink: 'Сілтемені көшіру',
    price: 'Бағасы',
    aviPromo: 'Осы опция бойынша арзан билеттерді табамыз. Айырмашылықтан ақша табыңыз.',
    find: 'Табу',
    aviNotFound: 'Тиісті билеттер табылған жоқ.Басқа нұсқада кесіңіз.',
    aviInProgress: 'Билеттерді іздеудеміз {seconds} секундқа дейін созылады.',
    aviNoLuggageResults: 'Осы бойынша арзан багажсыз билеттерді таптық',
    aviOnlyLuggageResults: 'Осы опция бойынша арзан багаж билеттерін таптық',
    aviResults: 'Осы опция бойынша арзан билеттер табылды',
    aviNoLuggage: 'Багажсыз',
    aviWithLuggage: 'Багажбен',
    aviBuy: 'Сатып алу үшін <span class="offer-option-tickets_ _ price" >{price} {currency}< / span>< / span>',
    aviBuyLabeled: 'Сатып алу үшін <span class="offer-option-tickets__price" >{price} {currency}</span> бойынша {{label }}</span>',
    details: 'Толығырақ',
    hide: 'Жасыру',
    flightThere: 'Ол жаққа ұшу',
    flightBack: 'Кері ұшу',
    onDesktop: 'Компьютерде',
    onPhone: 'Смартфонда',
    aviRedirectError: 'Бір нарсе қатте кетті. Кешіріңіз.',
    aviRedirectProgress: 'Сайтқа өтіңіз. Бірнеше секунд күтіңіз.',
    messageVia: 'Бізге жазыңыз',
    consolidatorBanner: 'Әуе билеттерін сатудан көбірек ақша табыңыз',
    consolidatorLink: 'Жарнамаға тапсырыс беру AVIATO.me',
    version: 'Құрастыру: {version}',
    offerTextWithLuggage: 'Багаж кіреді',
    offerTextWithoutLuggage: 'Тек қол жүгі',
    transferHoursMinutes: 'Трансплантация {hours} сағ. {minutes} М.',
    tutorialNewPnrTitle: 'Кез келген GDS PNR салыңыз',
    tutorialNewPnrContent: 'Кез-келген GDS-тің PNR-ін енгізу арқылы клиенттерге заманауи және ақпараттық ұсыныстар жасаңыз.',
    tutorialPriceTitle: 'Баға мен багажды қосыңыз',
    tutorialPriceContent: 'Ақпаратты жылдам қосу үшін белгілерді пайдаланыңыз. Барлық өзгерістер автоматты түрде сақталады.',
    tutorialLinkTitle: 'Сөйлемді көшіріңіз',
    tutorialLinkContent: 'Сөйлемді сілтеме немесе мәтін арқылы көшіруге және жіберуге болады.',
    tutorialLinkCta: 'Әрі қарай',
    tutorialNewOfferTitle: 'Жаңа ұсыныс жасаңыз',
    tutorialNewOfferContent: 'Клиентке ұсыныс дайын болғанда, жаңасын жасаңыз. Сілтемені басу арқылы бұрын жасалған сөйлемге оралыңыз.',
    tutorialNewOfferCta: 'Түсінікті',
    loginPopupTitle: 'Кіру',
    loginPopupHint: 'Тіркеліңіз, бұл тегін және 3 минуттан аспайды. Шексіз түрлендірулер мен жаңа функционалдылық қол жетімді болады.',
    loginPopupPlaceholder: 'Электрондық пошта',
    loginPopupCta: 'Жіберу',
    loginPopupCancel: 'Бас тарту',
    loginPopupSent: 'Жіберілді',
    loginPopupSentHint: 'Сіздің поштаңызға кіру сілтемесі AVIATO.me жіберілді',
    changeLang: 'In English',
    loginOneMinute: 'Бір минут...',
    loginError: 'Қате',
    optionsTo: '{airport}ішіндегі опциялар',
    cookies: 'Біз <a target="_blank" class="global-wrapper__cookie-link" href="{cookieLink}">куки</a> и <a class="global-wrapper__cookie-link" href="{gdprLink}" target="_blank">GDPR саясатын</a> қолданамыз',
    mainHeadline: 'PNR-ді жолаушы үшін түсінікті көрініске айналдырыңыз',
    mainSubHeadline: 'PNR-ді кез-келген GDS-тен салыңыз, біз оны жолаушы үшін түсінікті көрініске айналдырамыз.',
    warTitle: 'Біз Украинадамыз, бізде соғыс',
    warHint: 'Ол аяқталғаннан кейін біз ораламыз.Әзірге сіз <a class="war-link" href="https://uahelp.monobank.ua/" target="_blank">көмектесу</a> бұл процесті жылдамдату.',
    paymentBannerTitle: 'HERO бол',
    paymentBannerText: 'Жазылымды рәсімдеңіз AVIATO.me украин халқына қолдау көрсетіңіз',
    paymentBannerButton: 'Жазылымды рәсімдеу',
    paymentBannerDate: 'Дейін жарамды',
    paymentPopupCancel: 'Бас тарту',
    paymentPopupTitle: 'HERO бол',
    paymentSettingsButton: 'Жазылым параметрлері',
    paymentPopupText: '6 жыл бойы AVIATO әуе менеджерлерінің өмірін жеңілдету мақсатында жұмыс істеді. Алты ай жұмыс уақыты - біз 6 жыл ішінде көп уақытты үнемдедік. Осы уақыт ішінде ол тегін болды. Осы уақыт ішінде біз өнімді өз есебінен дамытып, қолдадық. Және бұл әрқашан жалғасуы мүмкін еді.',
    paymentPopupAngryText: 'Бірақ 2022 жылдың басында біздің елімізге соғыс келді, біздің барлық күш-жігеріміз өз өмірі мен бостандығы үшін күресуге бағытталған.',
    paymentPopupHeartText: '5 ақпаннан бастап 2023 AVIATO.me ішінара ақылы болады. Егер сіз күніне 5-тен көп түрлендірулер жасасаңыз, сізге жазылым ұсынылады.',
    paymentPopupHospitalText: 'Барлық пайда Украинаға Ерікті көмекке жұмсалады. Жазылу арқылы сіз украин халқына өмір сүру құқығын қорғауға көмектесесіз.',
    paymentPopupMontly: 'Бір айлық жазылым',
    paymentPopupMontlyTitle: 'ай',
    paymentPopupMontlyHelp: 'Ай ішінде төлем',
    paymentPopupYearly: 'Жылдық жазылым',
    paymentPopupYearlyHelp: 'Жыл ішінде төлем',
    paymentPopupCta: 'Төлеу',
    paymentFormCardHolder: 'Картадағы атау',
    paymentFormFullName: 'Толық аты',
    paymentFormCardDate: 'Мерзімі',
    paymentFormMM: 'ДД',
    paymentFormYY: 'ГГ',
    paymentFormExpirationDate: 'Мерзімі',
    paymentFormDate: 'Күн',
    paymentFormYear: 'Жыл',
    paymentFormSubmit: 'Төлеу',
    paymentFormCardNumber: 'Карта нөмірі',
    paymentFormLoading: 'Төлем жасалуда...',
    paymentErrorHappened: 'Қате болды, сізге көмектесу үшін бізге электрондық хат жіберіңіз',
    subscriptionSettingsPopupTitle: 'Сен HERO',
    subscriptionSettingsUntil: 'дейін',
    subscriptionSettingsUnsubscribe: 'Жазылудан бас тарту',
    unsubscribeTitle: 'Біз өте өкінеміз',
    unsubscribeText: 'Сіз жазылым мерзімі аяқталғанға дейін қызметті пайдалануды жалғастыра аласыз. Осыдан кейін сіз әдеттегі нұсқаға ауысасыз.',
    unsubscribeButtonText: 'Иә, мен жазылудан бас тартқым келеді',
    loginPopupMessage: 'AVIATO.me, пайдалануды жалғастыру үшін кіру керек',
    paymentBannerTitlePaid: 'Сен HERO',
    paymentBannerTextPaid: 'Сіз көмектесесіз AVIATO.me сіз украин халқын қолдайсыз дейін',
    paymentBannerTitleUntil: 'дейін',
    thankYouPopupTitle: 'Қолдау үшін рахмет',
    thankYouPopupMessage: 'Сіздің жазылымыңыз {validUntil} . дейін жарамды. Егер сізде сұрақтар немесе ескертулер болса, сіз тікелей Артемге жүгіне аласыз – akilmyashkin@gmail.com.',
    thankYouPopupOkay: 'Жарайды',
    logout: 'Профильден шығу',
    login: 'Профильге кіру',
    transferHours: '',
    landingTitle: '',
    landingText: '',
    landingCta: '',
    tipHeader: '',
    tipText: '',
    tipNext: '',
    newLoginTitle: '',
    newLoginText: '',
    gdprCookie: '',
    gdprCta: ''
  }
}
