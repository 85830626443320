import amplitude from 'amplitude-js'
import { AMPLITUDE_KEY, FLIGHTS_AMPLITUDE_KEY, PROJECTS } from './constants.js'
import { getVersion } from './utils/version'

export function sendEvent (name, properties) {
  amplitude.getInstance().logEvent(name, properties)
}

export function initTracking ({ user, locale, projectName }) {
  amplitude.getInstance().init(projectName === PROJECTS.AVIATO ? AMPLITUDE_KEY : FLIGHTS_AMPLITUDE_KEY)
  const identify = new amplitude.Identify()
  identify.set('locale', locale)
  identify.set('version', getVersion())
  identify.set('projectName', projectName)
  if (user && user.email) {
    identify.set('email', user.email)
  }
  amplitude.getInstance().identify(identify)
  amplitude.getInstance().setUserId(user ? user.id : null)
}
