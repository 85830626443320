<template>
    <div class="subscription-settings">
        <div v-if="this.step === 'info'">
            <SubscriptionLayout>
                <template v-slot:popup-title>
                    <span>
                        {{ $t('subscriptionSettingsPopupTitle') }}
                    </span>
                </template>

                <template>
                    <div class="subscription-settings__plan">
                        <SubscriptionPlan :type="user.subscriptionType" :showChargeHelp="!isCancelledSubscription">
                            <template v-slot:subscriptionTitleSuffix>
                                <span>
                                    {{ $t('subscriptionSettingsUntil') }} {{ subscriptionEndDateStr }}
                                </span>
                            </template>

                            <template v-slot:action v-if="!isCancelledSubscription">
                                <button class="subscription-settings__button" @click="unsubscribe">
                                    {{ $t('subscriptionSettingsUnsubscribe') }}
                                </button>
                            </template>
                        </SubscriptionPlan>
                    </div>
                </template>
            </SubscriptionLayout>
        </div>
        <div v-else>
            <p class="subscription-settings__icon">🙁</p>
            <p class="subscription-settings__title">
                {{ $t('unsubscribeTitle') }}
            </p>
            <p class="subscription-settings__text">
                {{ $t('unsubscribeText') }}
            </p>
            <div class="subscription-settings__usubscribe-button-container">
                <button class="subscription-settings__usubscribe-button" @click="cancelSubscriptions" :disabled="loading">
                    {{ $t('unsubscribeButtonText') }}
                </button>
            </div>
        </div>
        <button class="subscription-settings__close-btn" @click="closePopup" :disabled="loading">
            {{ $t('paymentPopupCancel') }}
        </button>
    </div>
</template>

<style lang="scss" scoped>
    .subscription-settings__plan {
        margin-top: 12px;
    }

    .subscription-settings__close-btn {
        position: absolute;
        right: -6px;
        top: -9px;
        font-weight: bold;
        color: #333333;
        opacity: .3;

        &:hover {
        opacity: .6;
        }
    }

    .subscription-settings {
        position: relative;
    }

    .subscription-settings__button {
        font-weight: 700;
        font-size: 11px;
        line-height: 15px;

        /* identical to box height */
        text-align: right;

        color: #333333;

        mix-blend-mode: normal;
        opacity: 0.25;
        transition: opacity 0.2s;

        &:hover {
            opacity: 0.5;
        }
    }

    .subscription-settings__icon {
        font-size: 41px;
    }

    .subscription-settings__title {
        font-size: 24px;
        color: rgba(51, 51, 51, 1);
        font-weight: bold;
    }

    .subscription-settings__text {
        font-size: 12px;
        color: rgba(51, 51, 51, 1)
    }

    .subscription-settings__usubscribe-button {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        text-align: right;

        color: #FFFFFF;
        background: #765BAD;
        mix-blend-mode: normal;
        opacity: 0.8;
        border-radius: 3px;
        transition: opacity 0.2s;

        padding: 4px 8px;
        margin-top: 12px;

        &:hover {
            opacity: 1;
        }
    }

    .subscription-settings__usubscribe-button-container {
        display: flex;
        justify-content: right;
    }
</style>

<script>
import { mapGetters } from 'vuex'
import { cancelSubscription } from '../../api'
import SubscriptionLayout from './shared/SubscriptionLayout.vue'
import SubscriptionPlan from './shared/SubscriptionPlan.vue'

const padLeft = function (str, paddingValue) {
  return String(paddingValue + str).slice(-paddingValue.length)
}

export default {
  components: {
    SubscriptionLayout,
    SubscriptionPlan
  },

  data () {
    return {
      step: 'info',
      loading: false
    }
  },

  computed: {
    subscriptionEndDateStr () {
      if (!this.user.isSubscribed) {
        return null
      }
      const date = new Date(this.user.subscriptionEndDate)
      const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()]
      const yearStr = String(year).slice(-2)
      return `${padLeft(day, '00')}.${padLeft(month + 1, '00')}.${yearStr}`
    },

    isCancelledSubscription () {
      return this.user.subscriptionStatus === 'CANCELLED'
    },

    ...mapGetters(['user'])
  },

  methods: {
    async cancelSubscriptions () {
      this.loading = true
      try {
        await cancelSubscription()
      } catch (e) {
        alert(this.$t('paymentErrorHappened'))
      } finally {
        this.loading = false
      }
      this.closePopup()
    },

    closePopup () {
      this.$store.dispatch('hideSubscriptionSettings')
    },

    unsubscribe () {
      this.step = 'unsubscribe'
    }
  }
}
</script>
