<template>
  <div class="global-wrapper">
    <div class="global-wrapper__cookie" v-if="!acceptedTerms && showTerms">
      <div class="global-wrapper__cookie-inner">
        <div class="global-wrapper__cookie-text">
          <avi-emoji>🍪</avi-emoji>
          <p class="global-wrapper__cookie-content">
            <span v-html="
              $t('cookies', {
                'cookieLink': 'https://www.cookiepolicygenerator.com/live.php?token=sYctdYHPDwhvR0e1ZrlMTGqSDd3G11Pf',
                'gdprLink': 'https://www.gdprprivacypolicy.net/live.php?token=53fBU4tnQiYdTHGMsLfZfu1V7xYCTbV4',
              })
            ">
            </span>
          </p>
        </div>
        <button
          class="global-wrapper__button"
          @click="accept"
        >
          OK
        </button>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
  .global-wrapper__cookie-link {
    color: #7658af;
    border-bottom: 1px solid rgba(118, 88, 175, 0.3);
  }
</style>

<style lang="scss" scoped>
  .global-wrapper__cookie {
    position: fixed;
    width: 100%;
    background: white;
    box-shadow: 0 3px 5px 0 rgba(51, 51, 51, 0.05);
    z-index: 999;
    height: 51px;
    bottom: 0;
  }

  .global-wrapper__cookie-inner {
    max-width: 640px;
    margin: auto;
    padding: 14px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .global-wrapper__cookie-text {
    display: flex;
    align-items: center;
  }

  .global-wrapper__cookie-content {
    margin-left: 6px;
    font-size: 12px;
    color: #333;
    opacity: .9;
  }

  .global-wrapper__button {
    opacity: 0.85;
    border-radius: 3px;
    background-color: #765bad;
    font-size: 12px;
    font-weight: bold;
    color: white;
    padding: 4px 8px;

    &:hover {
      opacity: 1;
    }
  }
</style>

<script>
import { mapGetters } from 'vuex'
import { PROJECTS } from '../../constants'

const KEY = 'cookieAccepted'

export default {
  data () {
    const accepted = localStorage && !!localStorage.getItem(KEY)
    return {
      accepted
    }
  },

  methods: {
    accept () {
      this.$store.commit('acceptTerms')
    }
  },

  computed: {
    showTerms () {
      return this.project.name === PROJECTS.AVIATO
    },

    ...mapGetters(['acceptedTerms', 'project'])
  }
}
</script>
