<template>
    <div :class="{
        'subscription-plan__option': true,
        'subscription-plan__option--highlighted': highlighted
    }">
        <div class="subscription-plan__option-header">
            <p class="subscription-plan__option-title">
                <span v-if="isMonthly">{{ $t('paymentPopupMontly') }}</span>
                <span v-else>{{ $t('paymentPopupYearly') }}</span>
                <span class="subscription-plan__title-suffix">
                    <slot name="subscriptionTitleSuffix"></slot>
                </span>
            </p>
            <slot name="action"></slot>
        </div>
        <p class="subscription-plan__option-price">
            <span v-if="isMonthly">9,99$</span>
            <span v-else>7,99$</span>
            <span class="subscription-plan__price-month">
                /{{ $t('paymentPopupMontlyTitle') }}
            </span>
        </p>
        <p class="subscription-plan__option-price-help" v-if="showChargeHelp">
            <span v-if="isMonthly">{{ $t('paymentPopupMontlyHelp') }} 9,99$</span>
            <span v-if="isYearly">{{ $t('paymentPopupYearlyHelp') }} 95,88$</span>
        </p>
    </div>
</template>

<style lang="scss" scoped>
    .subscription-plan__option {
      background: white;
      border: 1px solid rgba(51, 51, 51, 0.3);
      border-radius: 4px;
      padding: 8px;

      &:hover {
        cursor: pointer;
      }
    }

    .subscription-plan__title-suffix {
        font-weight: normal;
    }

    .subscription-plan__option--highlighted {
        border: 1px solid #765BAD;
        box-shadow: 0px 2px 10px 1px rgba(118, 91, 173, 0.4);
    }

    .subscription-plan__option-header {
      display: flex;
      justify-content: space-between;
    }

    .subscription-plan__option-title {
      font-size: 10px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }

    .subscription-plan__option-price {
      font-size: 20px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }

    .subscription-plan__option-price-help {
      font-size: 8px;
      color: rgba(51, 51, 51, 1);
    }

    .subscription-plan__price-month {
      font-size: 12px;
    }
</style>

<script>
export default {
  props: {
    highlighted: Boolean,
    type: String,
    showChargeHelp: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    isMonthly () {
      return this.type === 'MONTHLY'
    },

    isYearly () {
      return this.type === 'YEARLY'
    }
  }
}
</script>
