<template>
  <avi-popup>
    <div class="payment-popup">
        <SubscriptionSelection></SubscriptionSelection>
        <button class="payment-popup__close-btn" @click="closePopup">
        {{ $t('paymentPopupCancel') }}
        </button>
    </div>
  </avi-popup>
</template>

<style lang="scss" scoped>
.payment-popup__close-btn {
    position: absolute;
    right: -6px;
    top: -9px;
    font-weight: bold;
    color: #333333;
    opacity: .3;

    &:hover {
    opacity: .6;
    }
}
.payment-popup {
    position: relative;
    max-width: 434px;
}
</style>

<script>
import SubscriptionSelection from './SubscriptionSelection'

export default {
  components: {
    SubscriptionSelection
  },

  methods: {
    closePopup () {
      this.$store.dispatch('hidePaymentPopup')
    }
  }

}
</script>
