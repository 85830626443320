<template>
  <div class="layout">
    <div class="layout__shade" v-if="isShowingPopup"></div>
    <div :class="{
      'layout__app': true,
      'layout__app--terms': showGdprBanner
    }">
      <div class="layout__subscription">
        <slot name="subscription-menu"></slot>
      </div>
      <div :class="{
        'layout__top-menu-wrapper': true,
        'layout__top-menu-wrapper--banner': showGdprBanner,
      }">
        <div :class="{
          'layout__top-menu': true,
          'layout__top-menu--banner': showGdprBanner
        }">
          <slot name="top-menu"></slot>
        </div>
      </div>
      <div :class="{
        'layout__header-wrapper': true,
        'layout__header-wrapper--banner': showGdprBanner,
        }">
        <header class="layout__header">
          <slot name="header"></slot>
        </header>
      </div>
      <div :class="{
        'layout__body-wrapper': true,
        'layout__body-wrapper--banner': showGdprBanner,
      }">
        <div class="layout__body">
          <slot></slot>
        </div>
      </div>
      <div :class="{
        'layout__contact-us': true,
        'layout__contact-us--terms': showGdprBanner
      }">
        <div v-if="showExtension" class="layout__extension-banner">
          <ExtensionBanner />
        </div>
        <p class="layout__contact-us-header">{{ $t('messageVia') }}:</p>
        <a class="layout__contact-us-link layout__contact-us-link--mail" :href="mailTo">
          <span>{{ contactEmail }}</span>
        </a>
        <br/>
        <a v-if="showFacebook" class="layout__contact-us-link" href="https://www.facebook.com/aviato.me/" target="_blank">facebook</a>
      </div>
      <div v-if="showFooter">
        <div class="layout__footer">
          <slot name="footer"></slot>
        </div>
      </div>
      <p class="layout__build-version">
        <avi-button href='#' @click="logout" class="layout__login-logout" v-if="showLogin && user">{{ $t('logout') }}</avi-button>
        <avi-button href='#' @click="login" class="layout__login-logout" v-if="showLogin && !user">{{ $t('login') }}</avi-button>
        <br/>
        <span v-if="user">email: {{ user.email }}</span>
        <br v-if="user"/>
        {{ $t('version', { version }) }}
      </p>
    </div>
    <div v-if="isShowingPopup">
      <ThankYouPopup v-if="isShowingThankYouPopup"></ThankYouPopup>
      <PaymentPopup v-if="isShowingPaymentPopup"></PaymentPopup>
      <SubscriptionSettings v-if="isShowingSubscriptionSettings"></SubscriptionSettings>
    </div>
  </div>
</template>

<style lang="scss">
  @import './reset.css';

  .layout__login-logout {
    cursor: pointer;
    color: #765cad;

    &:hover {
      opacity: 0.8;
    }
  }

  body {
    margin: 0;
    background: #eeeeee;
    font-family: "Open Sans", sans-serif;
    font-feature-settings: 'kern';
    -webkit-font-smoothing: antialised;
    -moz-osx-font-smoothing: grayscale;

    @media screen and (max-width: 640px) {
      background: #f5f5f5;
    }
  }

  img {
    user-select: none;
  }
</style>

<style lang="scss" scoped>
  $banner-height: 51px;

  $subscription-height: 45px;

  $top-menu-height: 40px;

  $header-height: 46px;
  $header-height-mobile: 46px;

  $footer-height: 66px;
  $footer-height-mobile: 58px;

  .layout__extension-banner {
    margin-bottom: 12px;
  }

  .layout__shade {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10;
  }

  .layout__subscription {
    max-width: 640px;
    width: 100%;
    margin: 0 auto;
  }

  .layout__app {
    height: 100%;
    width: 100%;
    max-width: 640px;
    box-shadow: 0 1px 0 0 #eaeaea;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .layout__app--terms {
    padding-bottom: 50px;
  }

  .layout__top-menu-wrapper {
    height: $top-menu-height;
    z-index: 2;

    @media screen and (max-width: 640px) {
      display: none;
    }
  }

  .layout__top-menu {
    max-width: 640px;
    margin: 0 auto;
    height: $top-menu-height;
  }

  .layout__header-wrapper {
    z-index: 1;

    @media screen and (max-width: 640px) {
      top: 0;
    }
  }

  .layout__header {
    border-radius: 10px 10px 0 0;
    padding: 7px 12px 0;
    box-shadow: 0 1px 0 0 #eaeaea;
    background: white;
    max-width: 640px;
    margin: 0 auto;
    height: $header-height;

    @media screen and (max-width: 640px) {
      height: $header-height-mobile;
      border-radius: 0;
      background: none;
      padding: 0;
      min-height: auto;
      display: flex;
      align-items: center;
      box-shadow: none;
    }
  }

  .layout__body-wrapper {
    height: 100%;
    overflow: scroll;
    scrollbar-width: none;

    @media screen and (max-width: 640px) {
      margin-top: -8px;
    }
  }

  .layout__body {
    max-width: 640px;
    margin: 0 auto;
    padding: 0 20px 10px;
    background: #f5f5f5;
    height: 100%;
    overflow-y: auto;

    @media screen and (max-width: 640px) {
      background: transparent;
      padding: 0 4px 4px;
    }
  }

  .layout__footer {
    margin: 0 auto;
    max-width: 640px;
    padding: 10px 20px;
    background: #f5f5f5;
    height: $footer-height;

    @media screen and (max-width: 640px) {
      padding: 5px;
      height: $footer-height-mobile;
      background: transparent;
    }
  }

  .layout__contact-us {
    position: fixed;
    bottom: 12px;
    left: calc(50% - 530px);
    z-index: 10;
  }

  .layout__contact-us--terms {
    bottom: 62px;
  }

  .layout__contact-us-header {
    font-size: 11px;
    font-weight: bold;
    line-height: 16px;
    color: #5a5a5a;
  }

  .layout__contact-us-link {
    font-size: 11px;
    color: #4266b2;
    border-bottom: 1px solid rgba(66, 102, 178, 0.3);
    text-decoration: none;
    line-height: 16px;

    &:hover {
      border-bottom: 1px solid rgba(66, 102, 178, 0.5);
    }

    &--mail {
      color: #7658af;
      border-bottom: 1px solid rgba(118, 88, 175, 0.3);

      &:hover {
        border-bottom: 1px solid rgba(118, 88, 175, 0.5);
      }
    }
  }

  .layout__build-version {
    position: fixed;
    bottom: 8px;
    right: 8px;
    font-size: 11px;
    color: rgba(51, 51, 51, 0.6);
    z-index: 1;

    @media (max-width: 950px) {
      display: none;
    }
  }
</style>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getVersion } from '../../utils/version'
import { logout } from '../../api'
import { PROJECTS } from '../../constants'
import SubscriptionSettings from './SubscriptionSettings.vue'
import PaymentPopup from './PaymentPopup'
import ThankYouPopup from './ThankYouPopup'
import ExtensionBanner from './ExtensionBanner'

export default {
  components: {
    PaymentPopup,
    SubscriptionSettings,
    ThankYouPopup,
    ExtensionBanner
  },
  props: {
    showFooter: {
      type: Boolean,
      default: true
    },
    isSubscriptionEnabled: {
      type: Boolean,
      defalut: false
    }
  },

  data () {
    return {
      version: getVersion()
    }
  },

  mounted () {
    if (window.location.search.includes('paymentSuccess')) {
      window.history.replaceState({}, document.title, '/')
      if (this.isSubscribed) {
        this.showThankYouPopup()
      }
    }
  },

  methods: {
    ...mapActions(['showThankYouPopup', 'showLoginPopup']),

    async logout () {
      await logout()
      window.location.reload()
    },

    login () {
      this.showLoginPopup()
    }
  },

  computed: {
    ...mapGetters(['acceptedTerms', 'user', 'isShowingPaymentPopup', 'isShowingSubscriptionSettings', 'isShowingPopup', 'isSubscribed', 'isShowingThankYouPopup', 'project']),

    contactEmail () {
      if (this.project.name === PROJECTS.AVIATO) {
        return this.isSubscribed ? 'akilmyashkin@gmail.com' : 'kilmiashkin@gmail.com'
      } else {
        return 'pnrflights@gmail.com'
      }
    },

    showExtension () {
      return !window.__AVIATO_INSTALLED
    },

    showLogin () {
      return this.project.name === PROJECTS.AVIATO
    },

    showFacebook () {
      return this.project.name === PROJECTS.AVIATO
    },

    mailTo () {
      return `mailto:${this.contactEmail}`
    },

    showGdprBanner () {
      return this.project.name === PROJECTS.AVIATO && !this.acceptedTerms
    }
  }
}
</script>
