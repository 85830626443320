<template>
  <p v-if="error" class="login">
    {{ $t('loginError') }}
  </p>
  <p v-else class="login">
    {{ $t('loginOneMinute') }}
  </p>
</template>

<style scoped>
.login {
  text-align: center;
  padding-top: 100px;
}
</style>

<script>
import { login } from '../../api'

export default {
  data () {
    return {
      error: false
    }
  },

  mounted () {
    this.login()
  },

  methods: {
    async login () {
      const { loginId } = this.$route.params
      try {
        const { offerId } = await login(loginId)
        window.location.href = offerId ? `/${offerId}` : '/'
      } catch (e) {
        console.error(e)
        this.error = true
      }
    }
  }
}
</script>
