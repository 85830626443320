<template>
  <SegmentLayout
    :initialDestination="getInitialAirportValue(firstSegment.departure.airport)"
    :initialArrival="getInitialAirportValue(secondSegment.arrival.airport)"
    :onDestinationChange="onDestinationChange"
    :onArrivalChange="onArrivalChange"
    :departureTime="firstSegment.departure.timestamp"
    :arrivalTime="secondSegment.arrival.timestamp"
    :forceOverline="!isToDestination"
    :writeAccess="writeAccess"
  >
    <template>
      <div class="compound-segment">
        <img class="compound-segmnent__airline-img compound-segmnent__airline-img--left" :src="airlineToTransfer.image" />
        <div class="compound-segment__transfer">
          <p class="compound-segment__tranfer-name">{{ transfer }}</p>
          <div class="compound-segment__transfer-timeblock">
            <p class="compound-segment__transfer-time">{{ arrivalToTransferHours }}</p>
            <img class="compound-segment__transfer-image" src="./images/horizontal-transfer.svg" />
            <p class="compound-segment__transfer-time">{{ departureFromTransferHours }}</p>
          </div>
        </div>
        <img class="compound-segmnent__airline-img compound-segmnent__airline-img--right" :src="airlineFromTransfer.image" />
      </div>
    </template>
  </SegmentLayout>
</template>

<style lang="scss" scoped>
  .compound-segment {
    width: 100%;
    max-width: 219px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }

  .compound-segmnent__airline-img {
    width: 15px;
    height: 15px;

    &--left {
      margin-right: 10px;
    }

    &--right {
      margin-left: 10px;
    }
  }

  .compound-segment__tranfer-name {
    margin-bottom: 3px;
    text-align: center;
    color: rgba(51, 51, 51, 0.9);
    font-size: 12px;
  }

  .compound-segment__transfer-timeblock {
    display: flex;
    justify-content: space-between;
  }

  .compound-segment__transfer-time {
    font-size: 12px;
    color: #333;
  }

  .compound-segment__transfer-image {
    margin: 0 4px;
  }
</style>

<script>
import { formatTime } from '/app/date-utils'
import { getInitialAirportValue } from '../../utils'
import SegmentLayout from '../SegmentLayout'

export default {
  components: { SegmentLayout },

  props: {
    isToDestination: {
      type: Boolean,
      default: false
    },
    firstSegment: {
      type: Object
    },
    secondSegment: {
      type: Object
    },
    writeAccess: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    getInitialAirportValue,

    onDestinationChange (e) {
      this.$emit('destinationChange', e.target.value)
    },

    onArrivalChange (e) {
      this.$emit('arrivalChange', e.target.value)
    }
  },

  computed: {
    arrivalToTransferHours () {
      return formatTime(this.arrivalToTransferTime)
    },

    departureFromTransferHours () {
      return formatTime(this.departureFromTransferTime)
    },

    transfer () {
      return getInitialAirportValue(this.firstSegment.arrival.airport)
    },

    airlineToTransfer () {
      return this.firstSegment.airline
    },

    airlineFromTransfer () {
      return this.secondSegment.airline
    },

    departureToTransferTime () {
      return this.firstSegment.departure.timestamp
    },

    arrivalToTransferTime () {
      return this.firstSegment.arrival.timestamp
    },

    departureFromTransferTime () {
      return this.secondSegment.departure.timestamp
    },

    arrivalToDestinationTime () {
      return this.secondSegment.arrival.timestamp
    }
  }
}
</script>
