<template>
  <div class="editable-footer">
    <div class="editable-footer__tags-wrapper">
      <div class="editable-footer__tag-wrapper">
        <PriceTag
          :initial-value="offerOption.price"
          :active="isPriceActive"
          @change="onPriceChange"
          @blur="onPriceBlur"
        >
        </PriceTag>
      </div>
      &nbsp;
      <div v-if="luggageSelected" class="editable-footer__tag-wrapper">
        <Tag  @close="onResetLuggage" :close-on-any-click="true" :active="true" :closable="true">
          <p v-if="offerOption.luggage">{{ $t('luggageIncluded') }}</p>
          <p v-else>{{ $t('onlyHandLuggage') }}</p>
        </Tag>
        &nbsp;
      </div>
      <div v-else class="editable-footer__luggage-tags">
        <div class="editable-footer__tag-wrapper">
          <Tag @click="onIncludeLuggage">
            <p>{{ $t('luggageIncluded') }}</p>
          </Tag>
        </div>
        &nbsp;
        <div class="editable-footer__tag-wrapper">
          <Tag @click="onOnlyHandluggage">
            <p>{{ $t('onlyHandLuggage') }}</p>
          </Tag>
        </div>
        &nbsp;
      </div>
      <div
        class="editable-footer__tag-wrapper"
        :key="tag.tmpId"
        v-for="tag in offerOption.tags"
      >
        <EditableTag
          @blur="onCustomTagBlur(tag.tmpId)"
          @change="onChangeTag(tag.tmpId, $event)"
          @close="onCloseTag(tag.tmpId)"
          :focus-on-mount="focusTagsOnMount"
          :active="tag.active"
          :initial-value="tag.name"
        >
        </EditableTag>
        &nbsp;
      </div>
      <div class="editable-footer__tag-wrapper">
        <Tag @click="onAddNewTag">
          <p>
            <span>+ {{ $t('myLabel') }}</span>
          </p>
        </Tag>
      </div>
    </div>
    <DetailsButton
      v-if="isDetailedView"
      :expandable-arrows="false"
      :title="$t('hide')"
      @click="onToggleDetailsView"
    >
    </DetailsButton>
    <DetailsButton
      v-else
      :expandable-arrows="true"
      :title="$t('details')"
      @click="onToggleDetailsView"
    >
    </DetailsButton>
  </div>
</template>

<style scoped lang="scss">
  .editable-footer__tags-wrapper {
    min-height: 12px;
    margin-bottom: -3px;
  }

  .editable-footer__tag-wrapper {
    display: inline-block;
  }

  .editable-footer__luggage-tags {
    display: inline-block;
  }

  .editable-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(51, 51, 51, 0.1);
    padding: 10px 14px 10px 14px;
  }
</style>

<script>
import DetailsButton from '../DetailsButton'
import PriceTag from '../PriceTag'
import Tag from '../Tag'
import EditableTag from '../EditableTag'
import { sendEvent } from '../../../../amplitude.js'

export default {
  components: {
    DetailsButton,
    PriceTag,
    Tag,
    EditableTag
  },

  props: [
    'offerOption',
    'isPriceActive',
    'isDetailedView'
  ],

  data () {
    return {
      focusTagsOnMount: false
    }
  },

  methods: {
    onPriceChange (price) {
      this.$emit('priceChange', price)
    },

    onPriceBlur () {
      this.$emit('priceBlur')
    },

    onIncludeLuggage () {
      this.$emit('luggageChange', true)
    },

    onOnlyHandluggage () {
      this.$emit('luggageChange', false)
      sendEvent('Carry on add')
    },

    onResetLuggage () {
      this.$emit('luggageChange', null)
      sendEvent('Baggage delete')
    },

    onAddNewTag () {
      this.focusTagsOnMount = true
      this.$emit('addNewTag')
      sendEvent('Tag add')
    },

    onCustomTagBlur (tagId) {
      this.$emit('blurTag', tagId)
    },

    onChangeTag (tagId, value) {
      this.$emit('changeTag', { tagId, value })
    },

    onCloseTag (tagId) {
      this.$emit('closeTag', tagId)
      sendEvent('Tag delete')
    },

    onToggleDetailsView () {
      this.$emit('toggleDetailsView')
    }
  },

  computed: {
    luggageSelected () {
      return this.offerOption.luggage !== null
    }
  }
}
</script>
