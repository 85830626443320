import App from './screens/App'
import Offer from './screens/Offer/index.vue'
import Login from './screens/Login'
import GlobalWrapper from './screens/GlobalWrapper'
import Admin from './screens/Admin'
import TkAdmin from './screens/TkAdmin'

export const routes = [
  {
    path: '/',
    component: GlobalWrapper,
    children: [
      { path: '/', component: App },
      { path: '/:offerId', component: Offer },
      { path: '/login/:loginId', component: Login },
      { path: '/admin/subscriptions', component: Admin },
      { path: '/admin/tk', component: TkAdmin }
    ]
  }
]
