<template>
  <SegmentLayout
    :initialDestination="initialDestination"
    :initialArrival="initialArrival"
    :onDestinationChange="onDestinationChange"
    :onArrivalChange="onArrivalChange"
    :departureTime="departureTime"
    :arrivalTime="arrivalTime"
    :forceOverline="!isToDesination"
    :writeAccess="writeAccess"
  >
    <template>
      <div>
        <div class="segment__airline">
          <img class="segment__airline-img" :src="airline.image" />
          <p class="segment__airline-name">{{ airlineName }}</p>
        </div>
        <div class="segment__duration" v-if="segment.duration">
          {{ duration }}
        </div>
      </div>
    </template>
  </SegmentLayout>
</template>

<style lang="scss" scoped>
  .segment__airline {
    font-size: 12px;
    color: rgba(51, 51, 51, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .segment__airline-img {
    flex-shrink: 0;
    width: 15px !important;
    height: 15px !important;
  }

  .segment__airline-name {
    margin-left: 5px;
  }

  .segment__duration {
    display: flex;
    justify-content: center;
    font-size: 12px;
    color: rgba(51, 51, 51, 0.9);
  }
</style>

<script>
import SegmentLayout from '../SegmentLayout'
import { getInitialAirportValue, minutesToDuration } from '../../utils'

export default {
  components: { SegmentLayout },

  props: {
    isToDesination: {
      type: Boolean,
      default: false
    },
    segment: {
      type: Object
    },
    writeAccess: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onDestinationChange (e) {
      this.$emit('destinationChange', e.target.value)
    },

    onArrivalChange (e) {
      this.$emit('arrivalChange', e.target.value)
    }
  },

  computed: {
    airlineName () {
      const { code, name } = this.airline
      return name || code
    },

    duration () {
      if (!this.segment.duration) {
        return null
      }
      return minutesToDuration(this.segment.duration, this.$i18n.locale)
    },

    initialArrival () {
      return this.segment.arrival.alias || getInitialAirportValue(this.segment.arrival.airport)
    },

    initialDestination () {
      return this.segment.departure.alias || getInitialAirportValue(this.segment.departure.airport)
    },

    airline () {
      return this.segment.airline
    },

    departureTime () {
      return this.segment.departure.timestamp
    },

    arrivalTime () {
      return this.segment.arrival.timestamp
    }
  }
}
</script>
