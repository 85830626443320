<template>
  <div :class="{'segment-layout': true, 'segment-layout--overline': forceOverline}">
    <div class="segment-layout__checkpoint">
      <avi-input :disabled="!writeAccess" class="segment-layout__city-input" :value="initialDestination" @change="onDestinationChange"></avi-input>
      <p class="segment-layout__info">
        <span class="segment-layout__time">{{ departureHours }}</span>
        <span class="segment-layout__date">, {{ departureDate }}</span>
      </p>
    </div>
    <div class="segment-layout__center-elem">
      <slot></slot>
    </div>
    <div class="segment-layout__checkpoint">
      <avi-input :disabled="!writeAccess" class="segment-layout__city-input segment-layout__city-input--right" :value="initialArrival" @change="onArrivalChange"></avi-input>
      <p class="segment-layout__info segment-layout__info--right">
        <span class="segment-layout__time">{{ arrivalHours }}</span>
        <span class="segment-layout__date" v-if="arrivalDate">, {{ arrivalDate }}</span>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @mixin overline {
    &:after {
      content: "";
      height: 1px;
      display: block;
      background-image: linear-gradient(to right,rgba(51, 51, 51, 0.2) 50%,rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 3px 1px;
      background-repeat: repeat-x;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .segment-layout {
    position: relative;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:first-child) {
      @include overline;
    }

    &--overline {
      @include overline;
    }
  }

  .segment-layout__city-input {
    max-width: 100%;
    display: block;
    font-size: 12px;
    color: rgba(51, 51, 51, 0.8);
    font-weight: 400;
    margin-bottom: -1px;

    &--right {
      text-align: right;
    }

    &:hover {
      color: rgba(51, 51, 51, 0.9);
    }

    &:focus {
      color: #333333;
    }
  }

  .segment-layout__info {
    display: flex;
    align-items: flex-end;

    &--right {
      justify-content: flex-end;
    }
  }

  .segment-layout__time {
    color: rgba(51, 51, 51, 0.9);
    font-weight: bold;
    font-size: 14px;
  }

  .segment-layout__date {
    font-size: 12px;
    color: rgba(51, 51, 51, 0.8);
  }

  .segment-layout__checkpoint {
    min-width: 0;
  }

  .segment-layout__center-elem {
    flex: 1 0;
  }
</style>

<script>
import { formatDate, formatTime } from '/app/date-utils'

export default {
  props: {
    forceOverline: {
      type: Boolean,
      default: false
    },
    initialDestination: {
      type: String,
      default: ''
    },
    initialArrival: {
      type: String,
      default: ''
    },
    departureTime: {
      type: String
    },
    arrivalTime: {
      type: String
    },
    onDestinationChange: {
      type: Function,
      default: () => {}
    },
    onArrivalChange: {
      type: Function,
      default: () => {}
    },
    writeAccess: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    departure () {
      return new Date(this.departureTime)
    },

    arrival () {
      return new Date(this.arrivalTime)
    },

    departureHours () {
      return formatTime(this.departureTime)
    },

    departureDate () {
      return formatDate(this.departureTime)
    },

    arrivalHours () {
      return formatTime(this.arrivalTime)
    },

    arrivalDate () {
      if (this.departure.getDate() !== this.arrival.getDate()) {
        return formatDate(this.arrivalTime)
      }
      return null
    }
  }
}
</script>
