<template>
  <div class="offer-controls">
    <NewOfferLink></NewOfferLink>
    <LanguageSwitcher></LanguageSwitcher>
    <div v-if="showTutorial" class="offer-controls--tooltip">
      <avi-tooltip
        stepNumber="3"
        :stepsMax="TUTORIAL_STEPS"
        :title="$t('tutorialNewOfferTitle')"
        :content="$t('tutorialNewOfferContent')"
        :cta="$t('tutorialNewOfferCta')"
        :offsetLeft="'30px'"
        @click="onTooltipClick"
        orientation="top"
      >
        <template v-slot:icon>
          <avi-emoji>✈️</avi-emoji>
        </template>
      </avi-tooltip>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .offer-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .offer-controls--tooltip {
    position: absolute;
    z-index: 1;
    top: 46px;
  }

  .offer-controls__language {
    border-radius: 3px;
    background-color: #765bad;
    color: white;
    font-weight: bold;
    font-size: 12px;
    opacity: 0.8;
    transition: 100ms opacity;
    padding: 4px 8px;

    &:hover {
      opacity: 1;
    }
  }
</style>

<script>
import NewOfferLink from '../NewOfferLink'
import { TUTORIAL_STEPS } from '../../../../constants'
import LanguageSwitcher from '../../../shared/components/LanguageSwitcher'

export default {
  components: { NewOfferLink, LanguageSwitcher },

  props: ['showTutorial'],

  data () {
    return {
      TUTORIAL_STEPS
    }
  },

  methods: {
    onTooltipClick () {
      this.$emit('tutorialClick')
    }
  }
}
</script>
